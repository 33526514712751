import React, { Component } from "react";
import { dbFirestore, auth, storage, timestamp } from "../../@firebase";
import { Redirect } from "react-router-dom";
import "./detail-password.css";
import DatePicker from "react-datepicker";
import * as moment from "moment";
import loadImage from "blueimp-load-image";
import * as firebase from "firebase/app";
import Loader from "react-loader-spinner";
import { metasTags } from "../../#constants";
//Styles
import "react-datepicker/dist/react-datepicker.css";
require("firebase/auth");
const uuidv4 = require("uuid/v4");

class DetailAndPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logged: false,
      user: {},
      infoUser: {},
      name: "",
      phone: "",
      email: "",
      password: "",
      oldPassword: "",
      changeBirthday: false,
      birthday: undefined,
      isDateTimePickerVisible: false,
      redirect: false,
      selectedDay: undefined,
      image: "",
      previous_image: "",
      newImage: false,
      messageErrorName: false,
      messageErrorEmail: false,
      messageErrorPassword: false,
      errorGeneral: false,
      messageErrorOldPassword: false,
      errorBadPass: false,
      visibleLoader: false,
    };
    this.updateDetails = this.updateDetails.bind(this);
    this.handleBlurName = this.handleBlurName.bind(this);
    this.handleBlurPhone = this.handleBlurPhone.bind(this);
    this.handleBlurPass = this.handleBlurPass.bind(this);
    this.handlePass = this.handlePass.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleNewImage = this.handleNewImage.bind(this);
    this.deleteOldImage = this.deleteOldImage.bind(this);
    this.handleOldPass = this.handleOldPass.bind(this);
    this.handleBlurOldPass = this.handleBlurOldPass.bind(this);
    this._isMounted = false;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    metasTags.setMetaTags();
    auth.onAuthStateChanged((user) => {
      if (user) {
        var userLogged = auth.currentUser;
        this._isMounted &&
          this.setState({ user: userLogged, logged: true, redirect: false });

        dbFirestore
          .collection("users")
          .doc(userLogged.uid)
          .get()
          .then((snapshot) => {
            this._isMounted &&
              this.setState({
                infoUser: snapshot.data(),
                name: snapshot.data().name,
                image: snapshot.data().photo,
                phone: snapshot.data().phone,
                previous_image: snapshot.data().photo,
              });
            if (snapshot.data().birthday !== undefined) {
              const birthday = new Date(
                this.state.infoUser.birthday.seconds * 1000
              );
              this._isMounted &&
                this.setState({
                  birthday: birthday,
                  selectedDay: moment(birthday),
                });
            }
          });
      } else {
        this._isMounted &&
          this.setState({
            user: {},
            logged: false,
            infoUser: {},
            redirect: true,
          });
      }
    });
  }

  async updateDetails(e) {
    e.preventDefault();
    this._isMounted && this.setState({ visibleLoader: true });
    var error = false;
    this._isMounted && this.setState({ disabled: true, errorBadPass: false });
    if (this.state.name.length < 4) {
      this._isMounted &&
        this.setState({ messageErrorName: true, disabled: false });
      error = true;
    }
    if (this.state.password.length > 0 && this.state.password.length < 6) {
      this._isMounted &&
        this.setState({ messageErrorPassword: true, disabled: false });
      error = true;
    }

    if (this.state.phone === undefined || this.state.phone.length === 0) {
      this._isMounted &&
        this.setState({ messageErrorPhone: true, disabled: false });
      error = true;
    }

    if (
      this.state.oldPassword.length > 0 &&
      this.state.oldPassword.length < 6
    ) {
      this._isMounted &&
        this.setState({ messageErrorOldPassword: true, disabled: false });
      error = true;
    }

    if (error === false) {
      var birthday;
      if (this.state.changeBirthday)
        birthday = new Date(moment(this.state.birthday));
      else birthday = this.state.infoUser.birthday;

      try {
        await dbFirestore.collection("users").doc(this.state.user.uid).update({
          name: this.state.name,
          birthday: birthday,
          phone: this.state.phone,
          updatedAt: timestamp,
        });
        if (this.state.password.length > 0 && this.state.newImage) {
          try {
            let credentials = firebase.auth.EmailAuthProvider.credential(
              auth.currentUser.email,
              this.state.oldPassword
            );
            await auth.currentUser.reauthenticateAndRetrieveDataWithCredential(
              credentials
            );
            try {
              await auth.currentUser.updatePassword(this.state.password);
              try {
                let photo = await this.uploadImage(
                  this.state.image,
                  "-profile.jpg"
                );
                await this.deleteOldImage();
                await dbFirestore
                  .collection("users")
                  .doc(this.state.infoUser.id)
                  .update({ photo: photo });
                this._isMounted &&
                  this.setState({
                    redirect: true,
                    disabled: false,
                    visibleLoader: false,
                  });
              } catch (error) {
                console.error("Error upload images", error);
                this.setState({ disabled: false, visibleLoader: false });
              }
            } catch (error) {
              this._isMounted &&
                this.setState({
                  disabled: false,
                  errorBadPass: true,
                  visibleLoader: false,
                });
            }
          } catch (error) {
            console.error("Error change auth", error);
            this.setState({
              disabled: false,
              errorBadPass: true,
              visibleLoader: false,
            });
          }
        } else if (this.state.password.length > 0) {
          try {
            let credentials = firebase.auth.EmailAuthProvider.credential(
              auth.currentUser.email,
              this.state.oldPassword
            );
            await auth.currentUser.reauthenticateAndRetrieveDataWithCredential(
              credentials
            );
            try {
              await auth.currentUser.updatePassword(this.state.password);
              this._isMounted &&
                this.setState({
                  redirect: true,
                  disabled: false,
                  visibleLoader: false,
                });
            } catch (error) {
              console.error("Error in update", error);
              this._isMounted &&
                this.setState({
                  disabled: false,
                  errorBadPass: true,
                  visibleLoader: false,
                });
            }
          } catch (error) {
            console.error("Error in reauth", error);
            this._isMounted &&
              this.setState({
                disabled: false,
                errorBadPass: true,
                visibleLoader: false,
              });
          }
        } else if (this.state.newImage) {
          try {
            let photo = await this.uploadImage(
              this.state.image,
              "-profile.jpg"
            );
            await this.deleteOldImage();
            await dbFirestore
              .collection("users")
              .doc(this.state.infoUser.id)
              .update({ photo: photo });
            this._isMounted &&
              this.setState({
                redirect: true,
                disabled: false,
                visibleLoader: false,
              });
          } catch (error) {
            console.error("Error upload images", error);
            this._isMounted &&
              this.setState({ disabled: false, visibleLoader: false });
          }
        } else {
          this._isMounted &&
            this.setState({
              redirect: true,
              disabled: false,
              visibleLoader: false,
            });
        }
      } catch (error) {
        this._isMounted &&
          this.setState({ disabled: false, visibleLoader: false });
        console.error("Error ", error);
      }
    } else {
      console.log("Errors in form.");
      this._isMounted &&
        this.setState({ disabled: false, visibleLoader: false });
    }
  }

  handleDayChange(e) {
    this._isMounted &&
      this.setState({ changeBirthday: true, birthday: e, selectedDay: e });
  }

  handleBlurName(e) {
    const value = e.target.value;
    if (value.length < 4) {
      this._isMounted && this.setState({ messageErrorName: true });
    } else {
      this._isMounted && this.setState({ messageErrorName: false });
    }
  }

  handleName(e) {
    this.setState({ name: e.target.value });
  }

  handleBlurPhone(e) {
    const value = e.target.value;
    if (value.length === 0) {
      this._isMounted && this.setState({ messageErrorName: true });
    } else {
      this._isMounted && this.setState({ messageErrorName: false });
    }
  }

  handlePhone(e) {
    console.log(e.target.value);
    this.setState({ phone: e.target.value });
  }

  handleBlurPass(e) {
    const value = e.target.value;
    if (value.length > 0 && value.length < 6) {
      this._isMounted && this.setState({ messageErrorPassword: true });
    } else {
      this._isMounted && this.setState({ messageErrorPassword: false });
    }
  }

  handlePass(e) {
    const value = e.target.value;
    this._isMounted && this.setState({ password: value });
  }

  handleBlurOldPass(e) {
    const value = e.target.value;
    if (value.length > 0 && value.length < 6) {
      this._isMounted && this.setState({ messageErrorOldPassword: true });
    } else {
      this._isMounted && this.setState({ messageErrorOldPassword: false });
    }
  }

  handleOldPass(e) {
    const value = e.target.value;
    this._isMounted && this.setState({ oldPassword: value });
  }

  handleNewImage(e) {
    const loadImageOptions = { canvas: true };
    const file = e.target.files[0];
    loadImage.parseMetaData(file, (data) => {
      if (data.exif && data.exif.get("Orientation")) {
        loadImageOptions.orientation = data.exif.get("Orientation");
      }
      loadImage(
        file,
        (canvas) => {
          this.setState({
            image: canvas.toDataURL(file.type),
            newImage: true,
          });
        },
        loadImageOptions
      );
    });
  }

  uploadImage(file, type) {
    const name = `${uuidv4()}${type}`;
    return storage
      .ref("images")
      .child(name)
      .putString(file, "data_url")
      .then((snapshot) => snapshot.ref.getDownloadURL())
      .catch((error) => {
        throw new Error(error);
      });
  }

  async deleteOldImage() {
    if (this.state.previous_image) {
      var image = storage.refFromURL(this.state.previous_image);
      try {
        await image.delete();
        return true;
      } catch (error) {
        console.log(error);
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { selectedDay } = this.state;
    const imageUrl = this.state.image;
    return (
      <div className="DetailAndPassword">
        {this.state.visibleLoader && (
          <div className="loader-area">
            <div className="loader-box">
              <Loader type="Oval" color="#000" height="50" width="50" />
            </div>
          </div>
        )}
        <div className="container">
          <div className="setting-title_container my-4">
            <p className="mb-0 closit_title detail_title">Details & Password</p>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="grid">
                <figure className="effect-zoe">
                  {this.state.image !== "" && (
                    <img src={imageUrl} alt="Upload" className="image" />
                  )}
                  {!this.state.image !== "" && (
                    <img
                      src="/unknown-person.png"
                      alt="Upload"
                      className="image"
                    />
                  )}
                  <input
                    name="newImage"
                    type="file"
                    multiple
                    accept="image/*"
                    className="input-new-image"
                    onChange={this.handleNewImage}
                  />
                  <figcaption>
                    <p>Upload Image</p>
                    <input
                      name="newImage"
                      type="file"
                      multiple
                      accept="image/*"
                      className="input-new-image"
                      onChange={this.handleNewImage}
                    />
                    <span>
                      <i className="fas fa-camera" />
                    </span>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="settings_description">
                <form>
                  <div className="form-group">
                    <input
                      className="form-control mb-3 new-input settings-pd"
                      name="name"
                      type="text"
                      placeholder="Full Name"
                      required
                      onBlur={this.handleBlurName}
                      defaultValue={this.state.name}
                      onChange={this.handleName}
                    />
                    {this.state.messageErrorName && (
                      <p className="error-text error-name">
                        Your name need a minimum of 4 letters.
                      </p>
                    )}
                  </div>

                  <div className="form-group">
                    <input
                      className="form-control mb-3 new-input settings-pd"
                      name="phone"
                      type="text"
                      placeholder="Phone"
                      required
                      onBlur={this.handleBlurPhone}
                      defaultValue={this.state.phone}
                      onChange={this.handlePhone}
                    />
                    {this.state.messageErrorPhone && (
                      <p className="error-text error-name">
                        Enter your number phone.
                      </p>
                    )}
                  </div>

                  <div className="form-group">
                    <button
                      className="btn btn-secondary btn-menu btn-size"
                      onClick={() => {
                        document.querySelector(".datepicker-insta").focus();
                      }}
                    >
                      Pick your birthday
                    </button>
                  </div>
                  <div className="form-group">
                    <DatePicker
                      className="datepicker-insta form-control new-input settings-pd"
                      selected={selectedDay}
                      onChange={this.handleDayChange}
                      dateFormat="MM/DD/YYYY"
                      peekNextMonthshowMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Your birthday..."
                    />
                  </div>
                  <div className="password-container my-4">
                    <p>Password</p>
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control mb-3 new-input settings-pd"
                      name="password"
                      type="password"
                      placeholder="Write your password here"
                      required
                      onBlur={this.handleBlurOldPass}
                      onChange={this.handleOldPass}
                    />
                    {this.state.messageErrorOldPassword && (
                      <p className="error-text error-email">
                        Your password need a minimum of 6 characters
                      </p>
                    )}
                    {this.state.errorBadPass && (
                      <p className="error-text error-email">
                        Your password is not correct
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control mb-3 new-input settings-pd"
                      name="password"
                      type="password"
                      placeholder="Write a new password here"
                      required
                      onBlur={this.handleBlurPass}
                      onChange={this.handlePass}
                    />
                    {this.state.messageErrorPassword && (
                      <p className="error-text error-email">
                        Your new password need a minimum of 6 characters
                      </p>
                    )}
                  </div>

                  <div className="form-group mt-5">
                    <button
                      className="btn btn-secondary btn-menu btn-size"
                      onClick={this.updateDetails}
                      disabled={this.errorGeneral}
                    >
                      Update Details
                    </button>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-secondary btn-menu btn-size"
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                    >
                      GO BACK
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {this.state.redirect && <Redirect push to={"/"} />}
      </div>
    );
  }
}

export default DetailAndPassword;
