import React, { Component } from "react";
import { dbFirestore, auth } from "../../@firebase";
import { Link } from "react-router-dom";
import "./similar-lots.css";
import LoginForm from "../login";
import { ResponsiveEllipsis, formatNumber } from "../../#constants";

class SimilarLots extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      similarLots: [],
      user: {},
      logged: false,
      wishlist_lots: [],
      formVisible: false,
      formType: "",
      show: false,
    };
    this.checkLogin = this.checkLogin.bind(this);
    this.existWishList = this.existWishList.bind(this);
    this.gotoWishlistItem = this.gotoWishlistItem.bind(this);
    this.close = this.close.bind(this);
    this.displayForm = this.displayForm.bind(this);
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    this.init();
  }
  componentWillReceiveProps(newProps) {
    // this.props = newProps;
    // this.init();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async init() {
    await this.checkLogin();
    const lotInfo = await dbFirestore
      .collection("lots")
      .doc(this.props.lot)
      .get();
    let dataSource = [];
    let json = { data: [], key: "Others items in auction" };
    try {
      const lots = await dbFirestore
        .collection("lots")
        .where("publish", "==", true)
        .where("highlight", "==", true)
        .where("auction", "==", lotInfo.data().auction)
        .orderBy("updatedAt", "desc")
        .get();
      let dataLot;
      let number = 1;

      lots.forEach((lot, index) => {
        if (lot.data().id !== this.props.lot) {
          dataLot = lot.data();
          dataLot.number = number;
          dataLot.cantBids = lot.data().bids_users.length;
          json.data.push(dataLot);
          number++;
        }
        if (index === 5) {
          return;
        }
      });
      dataSource.push(json);

      if (json.data.length > 1) {
        this.setState({ show: true });
      }

      this._isMounted && this.setState({ data: dataSource });
      this._isMounted && this.setState({ similarLots: dataSource });
    } catch (err) {
      console.error(err);
    }
  }

  async checkLogin() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        var userLogged = auth.currentUser;
        this._isMounted && this.setState({ user: userLogged, logged: true });
        let snapshot = await dbFirestore
          .collection("users")
          .doc(user.uid)
          .get();

        this._isMounted &&
          this.setState({
            infoUser: snapshot.data(),
            wishlist_lots: snapshot.data().wishlist_lots,
          });
      } else {
        this._isMounted &&
          this.setState({ user: {}, logged: false, infoUser: {} });
      }
    });
  }

  existWishList(id) {
    let exist = this.state.wishlist_lots.filter((value) => value === id);
    if (exist.length === 1) {
      return true;
    }
    return false;
  }

  async gotoWishlistItem(id, action) {
    if (this.state.logged) {
      let newDataWishlist = this.state.wishlist_lots;
      if (action) {
        newDataWishlist.push(id);
        try {
          dbFirestore
            .collection("users")
            .doc(this.state.user.uid)
            .update({ wishlist_lots: newDataWishlist });
          this._isMounted && this.setState({ wishlist_lots: newDataWishlist });
        } catch (error) {
          console.error("Error ", error);
        }
      } else {
        //remove item
        let data = newDataWishlist.filter((value) => value !== id);
        try {
          dbFirestore
            .collection("users")
            .doc(this.state.user.uid)
            .update({ wishlist_lots: data });
          this._isMounted && this.setState({ wishlist_lots: data });
        } catch (error) {
          console.error("Error ", error);
        }
      }
    } else {
      //go to login
      this._isMounted &&
        this.setState({ formVisible: true, formType: "login" });
    }
  }

  close() {
    this._isMounted && this.setState({ formVisible: false });
  }

  displayForm(value) {
    this._isMounted && this.setState({ formVisible: true, formType: value });
  }

  renderItems() {
    if (this.state.data !== null && this.state.data !== undefined) {
      if (this.state.data[0] !== null && this.state.data[0] !== undefined) {
        let similars = [];
        for (var i = 0; i < this.state.data[0].data.length; i++) {
          // const prod = this.state.data[0].data[i];
          similars.push(
            <div className="col-6 col-md-15 " key={"similar-" + i}>
              <div className="card card-category mt-3">
                {/* <div className="wishlist-box">
                  {!this.state.logged && (
                    <i
                      className=" far fa-heart"
                      onClick={() => this.displayForm("login")}
                    />
                  )}

                  {this.state.logged && !this.existWishList(prod.id) && (
                    <i
                      className=" far fa-heart"
                      onClick={e => {
                        this.gotoWishlistItem(prod.id, 1);
                      }}
                    />
                  )}

                  {this.state.logged && this.existWishList(prod.id) && (
                    <i
                      className=" fas fa-heart"
                      onClick={e => {
                        this.gotoWishlistItem(prod.id, 0);
                      }}
                    />
                  )}
                </div> */}
                <Link
                  to={"/lot/" + this.state.data[0].data[i].id}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  className="special_height"
                >
                  <img
                    className="card-category-img img-lot-smilar"
                    src={this.state.data[0].data[i].principalImage}
                    alt={this.state.data[0].data[i].title}
                  />
                </Link>
                <div className="card-body card-category-body px-0">
                  <Link to={"/lots/" + this.state.data[0].data[i].id}>
                    <ResponsiveEllipsis
                      className="card-title card-category-title closit_text three-lines-titles mb-0"
                      text={this.state.data[0].data[i].title}
                      maxLine="3"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                  </Link>
                  {/* <ResponsiveEllipsis
                    className="card-text card-category-text"
                    text={this.state.data[0].data[i].description}
                    maxLine="4"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  /> */}
                  <p className="card-text card-category-text">
                    ${formatNumber(this.state.data[0].data[i].estimatedPrice)}
                  </p>
                </div>
              </div>
            </div>
          );
        }
        return similars;
      }
    }
  }

  render() {
    return (
      <div>
        {this.state.show === true && (
          <div className="Similar my-4 p-0">
            <div className="container">
              <div className="row">
                <div className="col-12 similar_title_container no-pd">
                  <div className="mb-2 border_bot col-12 col-sm-5 col-md-5 col-lg-5 px-0">
                    <p className="lot_detail_title closit_text mb-0 ">
                      Highlight lots
                    </p>
                  </div>
                </div>
                {this.renderItems()}
              </div>
            </div>
          </div>
        )}
        {this.state.formVisible && (
          <LoginForm
            typeForm={this.state.formType}
            close={this.close.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default SimilarLots;
