import React, { Component } from "react";
import "./causes.css";

class Causes extends Component {
  state = {};
  render() {
    return (
      <div className="Causes p-0">
        <div className="container">
          {/* <div className="row text-center causes_title_container">
            <div className="col-12 my-2">
              <h1 className="causes_title closit_title">Our Causes</h1>
            </div>
            <div className="col-12">
              <p className='causes_subtitle'>
                The partners and causes we hold close to our hearts and
                fearlessly champion
              </p>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default Causes;
