import React, { Component } from "react";
import "./settings.css";
import Account from "../../@components/account";
import Rewards from "../../@components/rewards";
import { metasTags } from "../../#constants";
class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: true,
      rewards: false,
      closit: false
    };
    this._isMounted = false;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    metasTags.setMetaTags();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Settings">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <ul
                className="nav  align-items-center new_bg row justify-content-center"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item text-center col-4 col-lg-2 align-self-center no-pd ">
                  <a
                    className="nav-link active no_bg hover active setting_nav"
                    id="pills-account-tab"
                    data-toggle="pill"
                    href="#pills-account"
                    role="tab"
                    aria-controls="pills-account"
                    aria-selected="true"
                    onClick={() => {
                      document
                        .getElementById("pills-account-tab")
                        .classList.remove("active");
                      document
                        .getElementById("pills-account-tab")
                        .classList.add("active");
                      this._isMounted &&
                        this.setState({
                          account: true,
                          rewards: false,
                          closit: false
                        });
                      window.scrollTo(0, 0);
                    }}
                  >
                    Account
                  </a>
                </li>
                {/* <li className="nav-item text-center col-4 col-lg-2 align-self-center no-pd">
                  <a
                    className="nav-link no_bg hover setting_nav"
                    id="pills-rewards-tab"
                    data-toggle="pill"
                    href="#pills-rewards"
                    role="tab"
                    aria-controls="pills-rewards"
                    aria-selected="false"
                    onClick={() => {
                      document
                        .getElementById("pills-rewards-tab")
                        .classList.remove("active");
                      document
                        .getElementById("pills-rewards-tab")
                        .classList.add("active");
                      this._isMounted &&
                        this.setState({
                          account: false,
                          rewards: true,
                          closit: false
                        });
                      window.scrollTo(0, 0);
                    }}
                  >
                    Rewards
                  </a>
                </li> */}
                {/* <li className="nav-item text-center col-4 col-lg-2 align-self-center no-pd">
                  <a
                    className="nav-link no_bg hover setting_nav"
                    id="pills-myClosit-tab"
                    data-toggle="pill"
                    href="#pills-myClosit"
                    role="tab"
                    aria-controls="pills-myClosit"
                    aria-selected="false"
                    onClick={() => {
                      document.getElementById("pills-myClosit-tab").classList.remove("active");
                      document.getElementById("pills-myClosit-tab").classList.add("active");
                      this.setState({account:false,rewards:false,closit:true });
                      window.scrollTo(0, 0);
                    }}
                  >
                    My closit
                  </a>
                  <a>My closit</a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>

        {/* Tab Content */}
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-account"
            role="tabpanel"
            aria-labelledby="pills-account-tab"
          >
            {this.state.account && <Account />}
          </div>
          <div
            className="tab-pane fade"
            id="pills-rewards"
            role="tabpanel"
            aria-labelledby="pills-rewards-tab"
          >
            {this.state.rewards && <Rewards />}
          </div>
          {/* <div
            className="tab-pane fade"
            id="pills-myClosit"
            role="tabpanel"
            aria-labelledby="pills-myClosit-tab"
          >
            {this.state.closit && (<h1>My ArtLife Works</h1>)}
          </div> */}
        </div>
      </div>
    );
  }
}

export default Settings;
