//Dependencies
import React, { Component } from "react";
import { auth, dbFirestore, timestamp, storage } from "../../@firebase";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./new-referral.css";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Constants, metasTags } from "../../#constants";
import loadImage from "blueimp-load-image";
import * as firebase from "firebase/app";
import Success from "../../@components/success";
require("firebase/auth");
const reg = /(.+)@(.+){2,}\.(.+){2,}/;
const uuidv4 = require("uuid/v4");


class NewReferralPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: "password",
            error: "",
            logged: false,
            title_alert: "",
            title_message: "",
            image: "",
            newImage: false,
            user: {},
            email: "",
            pass: "",
            name: "",
            redirect: false,
            hostID: "",
            rewardID: "",
            redirect404: false,
            active: true
        };
        this.handleSubmitRegister = this.handleSubmitRegister.bind(this);
        this.responseFacebook = this.responseFacebook.bind(this);
        this.showHide = this.showHide.bind(this);
        this.disposeOut = this.disposeOut.bind(this);
        this.notify = this.notify.bind(this);
        this.handleNewImage = this.handleNewImage.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.setPropByKey = this.setPropByKey.bind(this);
        this.countInvitedToHostUser = this.countInvitedToHostUser.bind(this);
        this._isMounted = false;
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.state.active) {
            document.querySelector("#root").classList.add("blockScroll");
        } else {
            document.querySelector("#root").classList.remove("blockScroll");
        }
        metasTags.setMetaTags();
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                this.setState({ redirect: true });
            }
            else {
                var querys = window.location.search.split("&");
                const uid = querys[0].split("=")[1];
                const rewardID = querys[1].split("=")[1];

                if (uid !== undefined && rewardID !== undefined) {
                    this.setState({ hostID: uid, rewardID: rewardID });
                    let responseUser = await dbFirestore.collection("users_identifier").doc(uid).get();
                    let responseRewards = await dbFirestore.collection("rewards").doc(rewardID).get();
                    if (!responseUser.exists || !responseRewards.exists) {
                        this.setState({ redirect404: true });
                    }
                }
                else {
                    this.setState({ redirect404: true });
                }
            }
        });
    }

    showHide(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            type: this.state.type === "input" ? "password" : "input"
        });
    }

    notify = () => {
        if (!toast.isActive(this.toastId)) {
            this.toastId = toast.info("We're signing you in to your account", {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                className: "notify",
                bodyClassName: "notify-body",
                position: "bottom-center",
                toastId: "login"
            });
        }
    };

    update = () => {
        toast.update(this.toastId, {
            render: <Success />,
            type: toast.TYPE.INFO,
            autoClose: 1000,
            hideProgressBar: true,
            className: "notify",
            bodyClassName: "notify-body",
            position: "bottom-center",
            toastId: "login"
        });
    };

    setPropByKey(value, key) {
        this.setState({ [key]: value });
    }

    async handleSubmitRegister(e) {
        e.preventDefault();
        const email = this.state.email;
        const pass = this.state.pass;
        const name = this.state.name;

        try {
            let authUser = await auth.createUserWithEmailAndPassword(email, pass);
            await dbFirestore
                .collection("users")
                .doc(authUser.user.uid)
                .set({
                    id: authUser.user.uid,
                    email: email,
                    name: name,
                    typeOf: "user",
                    cart: [],
                    wishlist: [],
                    wishlist_lots: [],
                    orders: [],
                    customer_id: "",
                    default_card: "",
                    address: [],
                    mapJson: "",
                    createdAt: timestamp,
                    photo: "",
                    referrals: []
                });
            let photo = await this.uploadImage(this.state.image, "-profile.jpg");
            await dbFirestore.collection("users").doc(authUser.user.uid).update({ photo: photo });
            await this.countInvitedToHostUser(authUser.user.uid);
            await dbFirestore.collection("users_identifier").doc(authUser.user.uid).set({ id: authUser.user.uid });
            document.querySelector("#root").classList.remove("blockScroll");
            this.setState({ redirect: true, active: false });

        } catch (error) {
            let errorMessage = error.message;
            this.setState({ error: errorMessage });
        }
    }

    handleBlurRegister(e) {
        const type = e.target.getAttribute("name");
        if (type === "pass") {
            const pass = document.querySelector(`.register-form input[name="pass"]`)
                .value;
            if (pass.length >= 6) {
                document
                    .querySelector(`.register-form input[name="pass"]`)
                    .classList.remove("error-input");
                document
                    .querySelector(`.register-form .error-pass`)
                    .classList.add("hidden");
            } else {
                document
                    .querySelector(`.register-form input[name="pass"]`)
                    .classList.add("error-input");
                document
                    .querySelector(`.register-form .error-pass`)
                    .classList.remove("hidden");
            }
        } else if (type === "email-reg") {
            const email = document.querySelector(
                `.register-form input[name="email-reg"]`
            ).value;
            if (reg.test(email) === true) {
                document
                    .querySelector(`.register-form input[name="email-reg"]`)
                    .classList.remove("error-input");
                document
                    .querySelector(`.register-form .error-email`)
                    .classList.add("hidden");
            } else {
                document
                    .querySelector(`.register-form input[name="email-reg"]`)
                    .classList.add("error-input");
                document
                    .querySelector(`.register-form .error-email`)
                    .classList.remove("hidden");
            }
        } else {
            const name = document.querySelector(`.register-form input[name="name"]`)
                .value;
            if (name.length > 4) {
                document
                    .querySelector(`.register-form input[name="name"]`)
                    .classList.remove("error-input");
                document
                    .querySelector(`.register-form .error-name`)
                    .classList.add("hidden");
            } else {
                document
                    .querySelector(`.register-form input[name="name"]`)
                    .classList.add("error-input");
                document
                    .querySelector(`.register-form .error-name`)
                    .classList.remove("hidden");
            }
        }
    }

    disposeOut() {
        document.querySelector("#root").classList.remove("blockScroll");
        this.setState({ redirect: true, active: false });
    }

    responseFacebook = async response => {
        var provider = firebase.auth.FacebookAuthProvider.credential(
            response.accessToken
        );
        var authUser = await firebase
            .auth()
            .signInAndRetrieveDataWithCredential(provider);
        try {
            var doc = await dbFirestore
                .collection("users")
                .doc(authUser.user.uid)
                .get();
            if (!doc.exists) {
                dbFirestore
                    .collection("users")
                    .doc(authUser.user.uid)
                    .set({
                        id: authUser.user.uid,
                        email: authUser.user.email,
                        name: authUser.user.displayName,
                        typeOf: "user",
                        cart: [],
                        wishlist: [],
                        wishlist_lots: [],
                        orders: [],
                        customer_id: "",
                        default_card: "",
                        address: [],
                        mapJson: "",
                        createdAt: timestamp
                    })
                    .then(docRef => {
                        this.setState({ visible: false });
                        //Go to close login modal
                        this.dispose();
                    })
                    .catch(error => {
                        this.setState({
                            visible: false
                        });
                        console.error("Error adding document: ", error);
                    });
            } else {
                this.setState({ visible: false });
                this.dispose();
            }
        } catch (error) {
            console.error(error);
        }
    };

    handleNewImage(e) {
        const loadImageOptions = { canvas: true };
        const file = e.target.files[0];
        loadImage.parseMetaData(file, data => {
            if (data.exif && data.exif.get("Orientation")) {
                loadImageOptions.orientation = data.exif.get("Orientation");
            }
            loadImage(
                file,
                canvas => {
                    this.setState({
                        image: canvas.toDataURL(file.type),
                        newImage: true
                    });
                },
                loadImageOptions
            );
        });
    }

    uploadImage(file, type) {
        const name = `${uuidv4()}${type}`;
        return storage
            .ref("images")
            .child(name)
            .putString(file, "data_url")
            .then(snapshot => snapshot.ref.getDownloadURL())
            .catch(error => {
                throw new Error(error);
            });
    }

    async countInvitedToHostUser(invitedID) {
        var countInvitedToHost = firebase.functions().httpsCallable("countInvitedToHost");
        try {
            await countInvitedToHost({ hostID: this.state.hostID, invitedID: invitedID, rewardID: this.state.rewardID });
        } catch (error) {
            console.log(error, "invited user not counted");
        }
    }

    componentWillUnmount() {
        this.setState({ active: false });
        document.querySelector("#root").classList.remove("blockScroll");
    }
    render() {
        const imageUrl = this.state.image;
        const FACEBOOK_APP_ID = Constants.facebookKey();
        return (
            <div className="NewReferral">
                {this.state.redirect && <Redirect push to={"/"} />}
                {this.state.redirect404 && <Redirect push to={"/not-found"} />}
                <div className="forms-box">
                    <div className="box-form">
                        <div className="container h-100">
                            <div className="row h-100 centered">
                                <div className="col-12 max-w-440">
                                    <div className="login-bg">
                                        <p className="close_x" onClick={this.disposeOut}>
                                            <i className="fas fa-times" />
                                        </p>
                                        <p className="slogan my-3">The art world online</p>
                                        <form
                                            className="register-form"
                                            onSubmit={this.handleSubmitRegister}
                                        >
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="grid">
                                                        <figure className="effect-zoe">
                                                            {this.state.image !== "" && (
                                                                <img
                                                                    src={imageUrl}
                                                                    alt="Upload"
                                                                    className="image"
                                                                />
                                                            )}
                                                            {!this.state.image !== "" && (
                                                                <img
                                                                    src="/unknown-person.png"
                                                                    alt="Upload"
                                                                    className="image"
                                                                />
                                                            )}
                                                            <input
                                                                name="newImage"
                                                                type="file"
                                                                multiple
                                                                accept="image/*"
                                                                className="input-new-image"
                                                                onChange={this.handleNewImage}
                                                                required
                                                            />
                                                            <figcaption>
                                                                <p>Upload Image</p>
                                                                <span>
                                                                    <i className="fas fa-camera" />
                                                                </span>
                                                            </figcaption>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <input
                                                    className="form-control mb-3 new-input"
                                                    type="email"
                                                    name="email-reg"
                                                    placeholder="Enter your email address"
                                                    value={this.state.email}
                                                    onBlur={this.handleBlurRegister}
                                                    onChange={e => {
                                                        this.setPropByKey(e.target.value, "email");
                                                    }}
                                                    required
                                                />
                                                <p className="hidden error-text error-email">
                                                    Please enter a valid email.
                            </p>
                                                <p className="error-text error-email">
                                                    {this.state.error}
                                                </p>
                                            </div>
                                            <div className="col-12">
                                                <input
                                                    className="form-control new-input"
                                                    type={this.state.type}
                                                    name="pass"
                                                    placeholder="Enter your password"
                                                    value={this.state.pass}
                                                    onBlur={this.handleBlurRegister}
                                                    onChange={e => {
                                                        this.setPropByKey(e.target.value, "pass");
                                                    }}
                                                    required
                                                />
                                                <div className="password-eye" onClick={this.showHide}>
                                                    {this.state.type === "input" ? (
                                                        <i className="fas fa-eye-slash" />
                                                    ) : (
                                                            <i className="fas fa-eye" />
                                                        )}
                                                </div>
                                                <p className="hidden error-text error-pass">
                                                    Password required.
                            </p>
                                            </div>
                                            <div className="col-12">
                                                <input
                                                    className="form-control new-input"
                                                    type="text"
                                                    name="name"
                                                    placeholder="Enter your full name"
                                                    value={this.state.name}
                                                    onBlur={this.handleBlurRegister}
                                                    onChange={e => {
                                                        this.setPropByKey(e.target.value, "name");
                                                    }}
                                                    required
                                                />
                                                <p className="hidden error-text error-name">
                                                    Full name is required.
                            </p>
                                            </div>
                                            <div className="col-12">
                                                <button
                                                    className="btn btn-secondary btn-menu btn-size"
                                                    type="submit"
                                                >
                                                    Sign up
                            </button>
                                                <p className="other-options">
                                                    Sign up using{" "}
                                                    <a href="/#" className="underlined-b">
                                                        <FacebookLogin
                                                            appId={FACEBOOK_APP_ID}
                                                            autoLoad={false}
                                                            callback={this.responseFacebook}
                                                            render={renderProps => (
                                                                <span onClick={renderProps.onClick}>
                                                                    Facebook
                                                                </span>
                                                            )}
                                                        />
                                                    </a>
                                                </p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NewReferralPage;