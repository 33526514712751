import React, { Component } from "react";
import { dbFirestore, auth } from "../../@firebase";
import { Redirect, Link } from "react-router-dom";
import "./detail-lot.css";
import LoginForm from "../../@components/login";
import SimilarLots from "../../@components/similar-lots";
import ShareModal from "../../@components/share-modal";
import ConditionModal from "../../@components/condition-modal";
import CountDownSan from "../../@components/countdown";
import {
  metasTagsLots,
  formatNumber,
  monthNames,
  metasTags,
} from "../../#constants";
import CarouselProduct from "../../@components/carousel-product";
import $ from "jquery";

class DetailLotPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableQuantity: 1,
      data: {
        title: "",
        estimatedPrice: 0,
        estimatedPriceMax: 0,
        description: "<p></p>",
        minimiumPrice: 0,
        bidAmount: 0,
        bids_users: [],
      },
      redirect404: false,
      logged: false,
      bidSelected: "",
      formVisible: false,
      timed: true,
      active: false,
      messageButton: "BID",
      redirect: false,
      bid: true,
      existWishlistLots: false,
      wishlistLots: [],
      modalShare: false,
      redirectAuctions: false,
      auction: {},
      photos: [],
      allLots: [],
      redirectNextLot: false,
      idNextLot: "",
      lotLength: 0,
      uid: "",
      isOpen: true,
    };
    this.makeBid = this.makeBid.bind(this);
    this.handleChangeBids = this.handleChangeBids.bind(this);
    this.terminate = this.terminate.bind(this);
    this.addToWishlist = this.addToWishlist.bind(this);
    this.removeToWishlist = this.removeToWishlist.bind(this);
    this.init = this.init.bind(this);

    this._isMounted = false;
    this._completed = false;
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
    window.scrollTo(0, 0);
    this._isMounted = true;
    const lotId = this.props.match.params.id;
    this.setState({
      redirectNextLot: false,
      idNextLot: "",
    });

    auth.onAuthStateChanged(async (user) => {
      var logged;
      if (user) logged = true;
      else {
        logged = false;
      }
      this._isMounted && this.setState({ logged: logged, user: user });

      if (logged) {
        const userLogged = await dbFirestore
          .collection("users")
          .doc(user.uid)
          .get();
        var wishlist = userLogged.data().wishlist_lots;
        var existWishlistLots;
        if (wishlist.indexOf(lotId) >= 0) existWishlistLots = true;
        else existWishlistLots = false;

        this._isMounted &&
          this.setState({
            existWishlistLots: existWishlistLots,
            wishlistLots: userLogged.data().wishlist_lots,
            uid: user.uid,
          });
      }
      this.init();
    });
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== null && nextProps !== undefined) {
      this.props = nextProps;
      this.componentDidMount();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async init() {
    //normal flow user enter to lot detail
    const lotId = this.props.match.params.id;
    let lot = null;

    try {
      let snapshot = await dbFirestore.collection("lots").doc(lotId).get();
      if (!snapshot.exists || !snapshot.data().publish) {
        this._isMounted && this.setState({ redirect404: true });
      }

      lot = snapshot.data();

      let lotData = await dbFirestore
        .collection("lots")
        .where("publish", "==", true)
        .get();
      const lotLength = [];

      lotData.forEach((item) => {
        lotLength.push(item.data());
      });

      this.setState({ lotLength: lotLength.length });

      //meta datos
      metasTagsLots.setMetaTags(lot);
      //end meta datos

      let photos = [];
      photos.push({ principalImage: lot.principalImage });

      let tam = lot.secondaryImages.length;
      for (let i = 0; i < tam; i++) {
        photos.push({ principalImage: lot.secondaryImages[i] });
      }

      this._isMounted && this.setState({ photos: photos, data: lot });

      //Se generan los bids correspodientes según los datos de la subasta
      this.generarBids(
        parseInt(lot.minimiumPrice, 10),
        parseInt(lot.maximiumPrice, 10),
        parseInt(lot.bidInterval, 10),
        lot.bidAmount
      );

      const infoAuctionPromise = this.getInfoAuction(lot);
      const allLotsPromise = this.getAllLots(lot);
      await Promise.all([infoAuctionPromise, allLotsPromise]);
    } catch (error) {
      console.log(error);
    }
  }

  getInfoAuction = async (lot) => {
    let snapshotAuction = await dbFirestore
      .collection("auctions")
      .doc(lot.auction)
      .get();
    if (!snapshotAuction.exists) {
      this._isMounted && this.setState({ redirect404: true });
    }

    const auction = snapshotAuction.data();
    let initDay = new Date(auction.start.seconds * 1000).getTime();
    let today = new Date().getTime();
    var timed = true;
    if (initDay < today) {
      timed = false;
    }

    this._isMounted && this.setState({ auction: auction, timed: timed });

    let seconds = Math.round(new Date().getTime() / 1000);
    let end = auction.end.seconds;
    let start = auction.start.seconds;
    this._isMounted && this.setState({ end: end, start: start });

    if (end < seconds) {
      if (this.state.logged) {
        if (this.state.user.uid === lot.userWin) {
          this._isMounted &&
            this.setState({ messageButton: "THANK YOU FOR YOUR BID" });
        } else {
          this._isMounted && this.setState({ messageButton: "SOLD" });
        }
      } else {
        this._isMounted && this.setState({ messageButton: "SOLD" });
      }
      //Se setea la que la subasta no está activa y que no está en timed.
      this._isMounted && this.setState({ active: false, timed: false });
    } else {
      if (start < seconds) {
        if (this.state.logged) {
          if (this.state.user.uid === lot.userWin) {
            this._isMounted &&
              this.setState({
                messageButton: "THANK YOU FOR YOUR BID",
                active: false,
              });
          } else {
            this._isMounted &&
              this.setState({ active: true, messageButton: "BID" });
          }
          this._isMounted && this.setState({ timed: false });
        }
      }
    }
  };

  getAllLots = async (lot) => {
    let snapshot = await dbFirestore
      .collection("lots")
      .where("publish", "==", true)
      .where("auction", "==", lot.auction)
      .orderBy("order", "asc")
      .get();

    let nextItem;
    if (!snapshot.empty) {
      let dataLot;
      const sizeDocs = snapshot.size;
      for (let i = 0; i < sizeDocs; i++) {
        dataLot = snapshot.docs[i].data();
        if (dataLot.id === lot.id) {
          if (i + 1 < sizeDocs) nextItem = snapshot.docs[i + 1].data().id;
          else nextItem = snapshot.docs[0].data().id;
          break;
        }
      }
    }
    this._isMounted && this.setState({ idNextLot: nextItem });
  };

  userBided() {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.data.bid && this.state.bid) {
        this._isMounted && this.setState({ bid: false });
        let bids_users = this.state.data.bids_users;

        if (bids_users[bids_users.length - 1] === this.state.user.uid) {
          return;
        }

        bids_users.push(this.state.user.uid);
        dbFirestore
          .collection("lots")
          .doc(this.state.data.id)
          .update({
            userWin: this.state.user.uid,
            bidAmount: this.props.location.state.data.bidSelected,
            bids_users: bids_users,
          })
          .catch((err) => console.error(err));
      }
    }
  }

  createHeaderTimed() {
    if (
      this.state.auction.end !== undefined &&
      this.state.auction.start !== undefined
    ) {
      let header = [];
      //auction timed
      header.push(
        <div
          className="row align-center align-items-center justify-content-end mt-3 header-lots"
          key="row-header"
        >
          {/* <div className="col-12 col-md-6 mb-2">
            <p className="lot_detail_title closit_text">
              {this.state.data.title}
            </p>
          </div> */}
          <div className="col-12 col-md-3">
            <p
              style={{
                textAlign: "center",
              }}
            >
              AUCTIONS STARTS IN:
            </p>
            {/*<Countdown date={new Date(this.state.auction.start.seconds * 1000).getTime()} renderer={this.renderer}/>*/}
            <CountDownSan
              date={new Date(
                this.state.auction.start.seconds * 1000
              ).toString()}
              className="CountDown"
              onEnd={() => {
                this.terminate();
              }}
            />
          </div>
        </div>
      );
      return header;
    }
  }

  createHeaderLived() {
    if (
      this.state.auction.end !== undefined &&
      this.state.auction.start !== undefined
    ) {
      let header = [];

      //auction live
      header.push(
        <div
          className="row align-center align-items-center justify-content-end mt-3 header-lots"
          key="row-header"
        >
          {/* <div className="col-12 col-md-6 mb-2">
            {this.processTitle(
              this.state.data.title,
              "lot_detail_title closit_text",
              "0.8rem"
            )}
          </div> */}
          <div className="col-12 col-md-3">
            <p
              style={{
                textAlign: "center",
              }}
            >
              AUCTIONS ENDS IN:
            </p>
            {/*<Countdown date={new Date(this.state.auction.end.seconds * 1000).getTime()} renderer={this.renderer}/>*/}
            <CountDownSan
              date={new Date(this.state.auction.end.seconds * 1000).toString()}
              className="CountDown"
            />
          </div>
        </div>
      );

      return header;
    }
  }

  processTitle = (title, className, size) => {
    if (title !== "") {
      const split_title_one = title.split("[");
      if (split_title_one.length === 1) {
        return <h1 className={className}>{title}</h1>;
      }
      const split_title_two = split_title_one[1].split("]");

      return (
        <h1 className={className}>
          {split_title_one[0]}
          <span style={{ fontSize: size, fontWeight: "normal" }}>
            [{split_title_two[0].toUpperCase()}]
          </span>
        </h1>
      );
    }
  };

  createInfoBoxLot() {
    const date = new Date(this.state.data.createdAt * 1000);
    const today = new Date();
    const day = date.getDate();
    const month = date.getMonth();
    const year = today.getFullYear();

    if (this.state !== null && this.state !== undefined) {
      if (this.state.data !== null && this.state.data !== undefined) {
        let infoBox = [];
        infoBox.push(
          <div className="information-box-lot" key="info-box-lot">
            {/* {this.processTitle(
              this.state.data.title,
              "lot_title closit_text mb-0"
            )} */}
            <div className="lot_navigation_container col-12 col-sm-8 col-md-8 col-lg-8 p-0 mb-3">
              <button
                type="button"
                className="btn btn-sm btn-lot-detail p-0"
                onClick={() => this.prevLot()}
              >
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  className="bi bi-chevron-left"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                  />
                </svg>
              </button>
              <p className=" closit_text mb-0">
                {this.state.data.order} of {this.state.lotLength} Lots
              </p>
              <button
                type="button"
                className="btn btn-sm btn-lot-detail p-0"
                onClick={() => this.nextLot()}
              >
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  className="bi bi-chevron-right"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </button>
            </div>
            <p className="mb-0 lot-description grey">
              Lot {this.state.data.order}
            </p>
            {/* <a
              href={this.state.data.linkTo}
              _blank
              className="lot_title closit_text mb-0"
            >
              {this.state.data.artist}
            
            </a> */}
            <p className="lot_title closit_text mb-0">
              {this.state.data.title}
            </p>
            <p className="lot-description grey">{this.state.data.subtitle}</p>

            {/* <div className="location_container mb-2">
              <p className="location mb-0 noMargin">
                {this.state.data.location}
              </p>
              <p className="location mb-0" style={{ color: "#a9a9a9" }}>
                |
              </p>

              <p className="location mb-0">
                {day} {monthNames[month]} {year}
              </p>
            </div> */}

            <div>
              <div
                className="description_container"
                dangerouslySetInnerHTML={{
                  __html: this.state.data.description,
                }}
              />
            </div>

            <p className="lot-description mt-3">
              <span className="closit_text">Estimated value:</span> $
              {formatNumber(this.state.data.estimatedPrice)} - $
              {formatNumber(this.state.data.estimatedPriceMax)}
            </p>
          </div>
        );
        return infoBox;
      }
    }
  }

  createDescriptionLot() {
    if (this.state !== null && this.state !== undefined) {
      if (this.state.data !== null && this.state.data !== undefined) {
        let description = [];
        description.push(
          <div key="description-box">
            <div className="col-12 p-0">
              <div className="mb-3 border_bot col-12 col-sm-5 col-md-5 col-lg-5 p-0">
                <p className="lot_detail_title closit_text mb-0">Artist Bio</p>
              </div>
              {/* <p
                className="lot_title closit_text mb-2"
                style={{ textDecoration: "none" }}
              >
                {this.state.data.artist}
              </p> */}
              <a
                href={this.state.data.linkTo}
                _blank
                className="lot_title closit_text"
                style={{ textDecoration: "underline" }}
              >
                {this.state.data.artist}
              </a>
              {/* <hr className="auction_separator my-0 mb-2" /> */}
              <div
                className="lot-detail-description pars-str mt-2"
                dangerouslySetInnerHTML={{
                  __html: this.state.data.artistDescription,
                }}
              ></div>
            </div>
          </div>
        );
        return description;
      }
    }
  }

  createOptionsLot() {
    if (this.state !== null && this.state !== undefined) {
      if (this.state.bids !== null && this.state.bids !== undefined) {
        let options = [];
        for (let index = 0; index < this.state.bids.length; index++) {
          options.push(
            <option
              value={this.state.bids[index].value}
              key={"op-lot-" + index}
            >
              {this.state.bids[index].label}
            </option>
          );
        }
        return options;
      }
    }
  }

  generarBids = (min, max, porcentaje, bidAmount) => {
    bidAmount = Number(bidAmount);
    let bids = [];
    let bid;

    if (bidAmount === 0) bid = min;
    else bid = bidAmount;

    let interval = 100;
    if (min > 5000) interval = 500;

    do {
      bids.push({ label: "$ " + formatNumber(bid.toString()), value: bid });
      bid += interval;
    } while (bid <= max);

    this._isMounted && this.setState({ bids: bids });
  };

  makeBid() {
    if (this.state.logged) {
      if (this.state.bidSelected === "") {
        //notify has need select a bid
        document.getElementById("lot-bid").classList.add("error");
        document.querySelector(".lot-error-text").classList.add("visible");
      } else {
        //do the bid redirect to confirm
        this._isMounted && this.setState({ redirect: true });
      }
    } else {
      //show login
      this._isMounted && this.setState({ formVisible: true });
    }
  }

  handleChangeBids(e) {
    document.getElementById("lot-bid").classList.remove("error");
    document.querySelector(".lot-error-text").classList.remove("visible");
    this._isMounted && this.setState({ bidSelected: e.target.value });
  }

  displayForm() {
    this._isMounted && this.setState({ formVisible: true, formType: "login" });
  }

  close() {
    this._isMounted && this.setState({ formVisible: false });
  }

  terminate() {
    if (this.state.timed) {
      this._isMounted && this.setState({ timed: false });
    } else {
      if (this.state.logged) {
        if (this.state.user.uid === this.state.data.userWin) {
          this._isMounted &&
            this.setState({ messageButton: "THANK YOU FOR YOUR BID" });
        } else {
          this._isMounted && this.setState({ messageButton: "Sold" });
        }
      } else {
        this._isMounted && this.setState({ messageButton: "Sold" });
      }
      //Se setea la que la subasta no está activa
      this._isMounted && this.setState({ active: false });
    }
  }

  async addToWishlist() {
    const lotId = this.props.match.params.id;
    if (this.state.logged) {
      var wishlist_lots = this.state.wishlistLots;
      wishlist_lots.push(lotId);
      try {
        await dbFirestore
          .collection("users")
          .doc(this.state.user.uid)
          .update({ wishlist_lots: wishlist_lots });
        this._isMounted &&
          this.setState({
            existWishlistLots: true,
            wishlistLots: wishlist_lots,
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      this._isMounted && this.setState({ formVisible: true });
    }
  }

  async removeToWishlist() {
    const lotId = this.props.match.params.id;
    let newWishlist = this.state.wishlistLots.filter(
      (value) => value !== lotId
    );
    try {
      await dbFirestore
        .collection("users")
        .doc(this.state.user.uid)
        .update({ wishlist_lots: newWishlist });
      this._isMounted &&
        this.setState({ existWishlistLots: false, wishlistLots: newWishlist });
    } catch (error) {
      console.log(error);
    }
  }

  nextLot = () => {
    if (this.state.idNextLot !== "") {
      this.setState({ redirectNextLot: true });
    }
  };

  prevLot = () => {
    this.props.history.goBack();
  };

  toggleAccordion = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <div className="DetailLotPage">
        {this.state.redirect404 && <Redirect push to={"/not-found"} />}
        {this.state.redirectAuctions && <Redirect push to={"/auctions"} />}
        {this.state.redirectNextLot && (
          <Redirect push to={"/lot/" + this.state.idNextLot} />
        )}

        <div className="container">
          {this.state.timed && this.createHeaderTimed()}
          {!this.state.timed && this.createHeaderLived()}
          <hr className="auction_separator" />
          <div className="row align-items-start justify-content-center mb-0 mb-lg-5 mt-4">
            <div className="col-12 col-sm-6 col-lg-4 center">
              {/* <img className="main_lot_img" src={this.state.data.principalImage} alt={this.state.data.title} /> */}
              <CarouselProduct
                photos={this.state.photos}
                data={this.state.data}
              />
              <div className="lot_icon_container text-center mt-4">
                {!this.state.existWishlistLots && (
                  <a
                    href="/#"
                    className="add-heart col-4"
                    onClick={() => {
                      this.addToWishlist();
                    }}
                  >
                    <i className="far fa-heart" />
                  </a>
                )}
                {this.state.existWishlistLots && (
                  <a
                    href="/#"
                    className="remove-heart col-4"
                    onClick={() => {
                      this.removeToWishlist();
                    }}
                  >
                    <i className="fas fa-heart" />
                  </a>
                )}
                <a
                  href="/#"
                  className="share-bag col-4"
                  data-toggle="modal"
                  data-target="#shareModal"
                >
                  <i className="fas fa-share-square" />
                </a>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              {this.createInfoBoxLot()}
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="lot_bid_container">
                <div className="form-group">
                  <select
                    className="form-control"
                    id="lot-bid"
                    onChange={this.handleChangeBids}
                    value={this.state.bidSelected}
                  >
                    <option value="" disabled>
                      Select Bid
                    </option>
                    {this.createOptionsLot()}
                  </select>
                  <p className="lot-error-text">You must select a bid</p>
                </div>
                <div className="btn_container center">
                  <button
                    className="btn btn-secondary btn-sm btn-lot-detail"
                    // disabled={!this.state.active}
                    onClick={this.makeBid}
                  >
                    {this.state.messageButton}
                  </button>

                  {/* <button
                    onClick={() => this.nextLot()}
                    className="btn btn-secondary btn-sm btn-lot-detail"
                    style={{
                      marginTop: 10,
                    }}
                  >
                    NEXT LOT
                  </button> */}

                  <Link to={"/auction/" + this.state.data.auction}>
                    <button
                      className="btn btn-secondary btn-sm btn-lot-detail"
                      style={{
                        marginTop: 10,
                      }}
                    >
                      SEE MORE LOTS
                    </button>
                  </Link>
                </div>
              </div>
              {this.state.data.bidAmount === 0 ? (
                <div className="flex mt-4 mb-0">
                  <p className="bit-amount mr-2  mb-0">
                    Starting Bid: ${formatNumber(this.state.data.minimiumPrice)}
                  </p>
                  {/* <i
                      class="far fa-question-circle"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="A Buyer’s Premium of 25% will be added to every winning bid."
                    ></i> */}
                </div>
              ) : (
                <div className="flex mt-4 mb-0">
                  <p className="bit-amount mb-0 mr-2">
                    Current Bid: ${formatNumber(this.state.data.bidAmount)}
                  </p>
                  {/* <i
                    class="far fa-question-circle"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="A Buyer’s Premium of 25% will be added to every winning bid."
                  ></i> */}
                </div>
              )}
              <div className="flex mb-0">
                <p className="lot-description mb-0 mr-2">
                  w/ Buyer's Premium (BP)
                </p>
                <i
                  class="far fa-question-circle"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="A Buyer’s Premium of 25% will be added to every winning bid."
                ></i>
              </div>
              <p className="lot-description">
                (Bids: {this.state.data.bids_users.length})
              </p>
              <div className="mb-4">
                <p className="mb-0 disclaimer">
                  Shipping, taxes, and additional fees may apply.
                </p>
              </div>
            </div>
          </div>

          {/* COLLAPSE START */}
          <div className="accordion mb-4" id="accordionExample">
            <div>
              <div className="py-2" id="headingOne">
                <p
                  className="mb-0"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    className="btn btn-block text-left sale_overview px-0"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    onClick={() => this.toggleAccordion()}
                  >
                    Sale overview
                  </button>

                  <span
                    className="circle"
                    style={{ cursor: "pointer" }}
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    onClick={() => this.toggleAccordion()}
                  >
                    {this.state.isOpen ? (
                      <i className="fas fa-plus"></i>
                    ) : (
                      <i className="fas fa-minus"></i>
                    )}
                  </span>
                </p>
              </div>

              <div
                id="collapseOne"
                className="collapse hide"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div className="card-body">
                  <div className="col-12 col-sm-5 col-md-5 col-lg-5 p-0 mb-4">
                    <p className="lot_detail_title closit_text mb-2 border_bot">
                      Condition Report
                    </p>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 p-0">
                      <button
                        className="btn btn-secondary btn-sm btn-lot-detail"
                        style={{
                          marginTop: 15,
                        }}
                        data-toggle="modal"
                        data-target="#conditionModal"
                        disabled={!this.state.logged}
                      >
                        REQUEST REPORT
                      </button>
                    </div>
                  </div>

                  <div className="contact_specialist_container mt-4 col-12 col-sm-5 col-md-5 col-lg-5 mb-4">
                    <p className="lot_detail_title closit_text mb-1">
                      Contact Specialist
                    </p>
                    <p className="lot_description mb-0">
                      {this.state.data.specialist_name}
                    </p>
                    <p className="lot_description mb-0">
                      {this.state.data.specialist_exp}
                    </p>
                    <p className="lot_description mb-0">
                      {this.state.data.specialist_location}
                    </p>
                    <p className="lot_description mb-0">
                      {this.state.data.specialist_phone}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* COLLAPSE END */}

          <div className="col-12 col-sm-5 col-md-5 col-lg-5 p-0 mb-4">
            <div className="mb-2 border_bot">
              <p className="lot_detail_title closit_text mb-0">Provenance</p>
            </div>

            <div
              className="lot-detail-description mb-0 noMargin"
              dangerouslySetInnerHTML={{
                __html: this.state.data.provenance,
              }}
            ></div>
          </div>

          <div className="lot-description-area">
            {this.createDescriptionLot()}
          </div>

          <div className="lot-description-area">
            <SimilarLots lot={this.props.match.params.id} />
          </div>
        </div>

        {/* SHARE MODAL */}
        <ShareModal shareLink={window.location.href} />
        {/* END SHARE MODAL */}

        {/* CONDITION MODAL */}
        <ConditionModal
          LotId={this.state.data.id}
          title={this.state.data.title}
          userId={this.state.uid}
        />
        {/* END CONDITION MODAL */}

        {this.state.redirect && (
          <Redirect
            push
            to={{
              pathname: "/confirm-lot",
              state: {
                data: {
                  auction: this.state.auction,
                  bidSelected: this.state.bidSelected,
                  id: this.state.data.id,
                },
              },
            }}
          />
        )}
        {this.state.formVisible && (
          <LoginForm typeForm="login" close={this.close.bind(this)} />
        )}
      </div>
    );
  }
}

export default DetailLotPage;
