//Dependencies
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "./pick-place.css";
import Loader from "react-loader-spinner";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { toast } from "react-toastify";
import { dbFirestore, auth } from "../../@firebase";
import { MyMapComponent, metasTags } from "../../#constants";
import * as firebase from "firebase/app";
require("firebase/functions");

class PickPlacePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMarkerShown: false,
      address: "",
      fullname: "",
      floor: "",
      markerLog: 2.4597668,
      markerLat: 48.8152937,
      user: {},
      addressShippoTo: {
        street1: "",
        city: "",
        state: "",
        state_tl: "",
        zip: "",
        country: "",
        country_tl: "",
        phone: "",
      },
      redirect: false,
      redirectLog: false,
      visibleLoader: false,
      checkout: false,
      title_alert: "",
      message_alert: "",
      visibleNotify: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.addAddress = this.addAddress.bind(this);
    this.handleFloor = this.handleFloor.bind(this);
    this.handleName = this.handleName.bind(this);
    this.verifyAddress = this.verifyAddress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setProps = this.setProps.bind(this);
    this.init = this.init.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    metasTags.setMetaTags();
    this.setProps();
    auth.onAuthStateChanged((user) => {
      if (user) {
        var userLogged = auth.currentUser;
        this._isMounted &&
          this.setState({ user: userLogged, redirectLog: false });
        this.init();
      } else {
        this._isMounted &&
          this.setState({ user: {}, redirectLog: true, infoUser: {} });
      }
    });
  }

  async init() {
    let snapshot = await dbFirestore
      .collection("users")
      .doc(auth.currentUser.uid)
      .get();
    if (snapshot.exists) {
      this._isMounted && this.setState({ infoUser: snapshot.data() });
    }
  }

  setProps() {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.data.checkout) {
        this._isMounted && this.setState({ checkout: true });
      }
    } else {
      //sin props se debe mandar al home
      this._isMounted && this.setState({ redirectLog: true });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  //React auto complete
  handleChange(address) {
    this._isMounted && this.setState({ address });
  }

  async handleSelect(address) {
    try {
      var result = await geocodeByAddress(address);
      var latlng = await getLatLng(result[0]);
      this._isMounted &&
        this.setState({
          isMarkerShown: true,
          markerLog: latlng.lng,
          markerLat: latlng.lat,
        });
      let jsonAddress = {
        name: this.state.infoUser.name,
        email: this.state.infoUser.email,
        street1: "",
        city: "",
        state: "",
        state_tl: "",
        zip: "",
        country: "",
        country_tl: "",
        phone: "",
      };
      result[0].address_components.forEach((value) => {
        var x = value.types[0];
        switch (x) {
          case "street_number":
            jsonAddress.street1 = value.long_name;
            break;
          case "route":
            if (jsonAddress.street1 === "")
              jsonAddress.street1 = value.long_name;
            else
              jsonAddress.street1 = jsonAddress.street1 + " " + value.long_name;
            break;
          case "locality":
            jsonAddress.city = value.long_name;
            break;
          case "administrative_area_level_1":
            jsonAddress.state = value.long_name;
            jsonAddress.state_tl = value.short_name;
            break;
          case "postal_code":
            jsonAddress.zip = value.long_name;
            break;
          case "country":
            jsonAddress.country = value.long_name;
            jsonAddress.country_tl = value.short_name;
            break;

          default:
            break;
        }
      });

      this._isMounted &&
        this.setState({
          addressShippoTo: jsonAddress,
          address: result[0].formatted_address,
        });
    } catch (error) {
      console.error(error);
    }
  }

  showNotification() {
    toast.info(
      <div>
        <p className="notify-title">{this.state.title_alert}</p>
        <p className="notify-description">{this.state.message_alert}</p>
      </div>,
      {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: "notify",
        bodyClassName: "notify-body",
      }
    );
  }

  verifyAddress() {
    const data = this.state.addressShippoTo;
    if (
      data.street1 !== "" &&
      data.city !== "" &&
      data.state !== "" &&
      data.state_tl !== "" &&
      data.zip !== "" &&
      data.country !== "" &&
      data.country_tl !== ""
    ) {
      this.addAddress();
    } else {
      document.getElementById("btn-modal").click();
    }
  }

  async addAddress() {
    this._isMounted && this.setState({ visibleLoader: true });
    let snapshot = await dbFirestore
      .collection("users")
      .doc(this.state.user.uid)
      .get();
    try {
      if (snapshot.exists) {
        let address = snapshot.data().address;
        let addressShippoTo = JSON.parse(
          JSON.stringify(this.state.addressShippoTo)
        );
        addressShippoTo.street1 =
          addressShippoTo.street1 + " " + this.state.floor;
        //Verificando si la direccion es correcta

        const verifyShippoAddress = firebase
          .functions()
          .httpsCallable("verifyShippoAddress");
        try {
          const result = await verifyShippoAddress({
            address: addressShippoTo,
          });

          //Sino es correcta se le muestra una alerta al usuario y se rompe la ejecución
          if (Object.keys(result.data.validation_results).length === 0) {
            this._isMounted &&
              this.setState(
                {
                  title_alert: "Address Error",
                  message_alert:
                    result.data.validation_results.messages[0].text,
                  visibleLoader: false,
                },
                () => {
                  this.showNotification();
                  document.getElementById("btn-modal").click();
                }
              );

            return;
          }

          if (!result.data.validation_results.is_valid) {
            this._isMounted &&
              this.setState(
                {
                  title_alert: "Address Error",
                  message_alert:
                    result.data.validation_results.messages[0].text,
                  visibleLoader: false,
                },
                () => {
                  this.showNotification();
                  document.getElementById("btn-modal").click();
                }
              );

            return;
          }
        } catch (error) {
          this._isMounted &&
            this.setState(
              {
                title_alert: "Address Error",
                message_alert: error.message,
                visibleLoader: false,
              },
              () => {
                this.showNotification();
                document.getElementById("btn-modal").click();
              }
            );
          return;
        }

        if (address.length === 0)
          address.push({
            address: this.state.address,
            name: this.state.fullname,
            default: true,
            addressShippoTo: addressShippoTo,
          });
        else
          address.push({
            address: this.state.address,
            name: this.state.fullname,
            default: false,
            addressShippoTo: addressShippoTo,
          });

        await dbFirestore
          .collection("users")
          .doc(this.state.user.uid)
          .update({ address: address });
        this._isMounted &&
          this.setState({ redirect: true, visibleLoader: false });
      }
    } catch (error) {
      this._isMounted && this.setState({ visibleLoader: false });
      console.error("Error", error);
    }
  }

  handleFloor(e) {
    this._isMounted && this.setState({ floor: e.target.value });
  }

  handleName(e) {
    this._isMounted && this.setState({ fullname: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    var data = this.state.addressShippoTo;
    data.name = this.state.infoUser.name;
    data.email = this.state.infoUser.email;
    this._isMounted && this.setState({ addressShippoTo: data });
    this.addAddress();
    document.getElementById("modal-review-close").click();
  }

  render() {
    const isMarkerShown = this.state.isMarkerShown;
    const markerLog = this.state.markerLog;
    const markerLat = this.state.markerLat;
    return (
      <div className="NewAddress">
        {this.state.visibleLoader && (
          <div className="loader-area">
            <div className="loader-box">
              <Loader type="Oval" color="#000" height="50" width="50" />
            </div>
          </div>
        )}
        {this.state.redirectLog && <Redirect push to={"/"} />}
        <div className="container-fluid no-pd-mobile">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="title-new-address text-center closit_text my-3">
                  New shipping address
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <MyMapComponent
                  isMarkerShown={isMarkerShown}
                  markerLat={markerLat}
                  markerLog={markerLog}
                />
              </div>
            </div>
            <div className="row my-3">
              <div className="col-12">
                <form className="form-address">
                  <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    shouldFetchSuggestions={this.state.address.length > 2}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="form-group">
                        <input
                          {...getInputProps({
                            placeholder: "Street Address or Place...*",
                            className:
                              "form-control location-search-input location-form-input",
                          })}
                          value={this.state.address}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                  <input
                    className="form-control location-form-input"
                    onChange={this.handleFloor}
                    type="text"
                    name="location"
                    placeholder="Floor,door,apt...*"
                    value={this.state.floor}
                    required
                  />
                  <input
                    className="form-control location-form-input"
                    onChange={this.handleName}
                    type="text"
                    name="location"
                    placeholder="Full Name"
                    required
                  />
                </form>
              </div>
              <div className="col-12 col-md-6">
                <button
                  className="btn btn-secondary btn-new-add-map"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  GO BACK
                </button>
              </div>
              <div className="col-12 col-md-6">
                <button
                  className="btn btn-secondary btn-new-add-map"
                  onClick={(e) => {
                    this.verifyAddress(e);
                  }}
                  disabled={
                    this.state.address === "" ||
                    this.state.floor === "" ||
                    this.state.fullname === ""
                  }
                >
                  Save new shipping address
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.redirect && (
          <Redirect
            push
            to={{
              pathname: "/address-book/",
              state: { data: { checkout: this.state.checkout } },
            }}
          />
        )}
        {/*BEGIN Form in case of autocomplete failed*/}
        <button
          type="button"
          className="btn btn-primary d-none"
          data-toggle="modal"
          data-target="#completeManual"
          id="btn-modal"
        />
        <div
          className="modal fade"
          id="completeManual"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="completeManualTitle"
          aria-hidden="true"
        >
          <div
            className="container modal-dialog modal-dialog-centered"
            role="document"
          >
            <div className="modal-content star-modal">
              <button
                type="button"
                className="close x"
                data-dismiss="modal"
                aria-label="Close"
                id="modal-review-close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-header">
                <div className="col-12 col-lg-12">
                  <p
                    className="modal-title star-title_container mt-3"
                    id="completeManualTitle"
                  >
                    We have detected that our autocomplete did not obtain all
                    the data we need please enter it here.
                  </p>
                </div>
              </div>
              <div className="modal-body">
                <form onSubmit={this.handleSubmit}>
                  <div className="col-12">
                    <input
                      className="form-control modal-input"
                      name="name"
                      type="text"
                      placeholder="Street"
                      value={this.state.addressShippoTo.street1}
                      required
                      onChange={(e) => {
                        let aux = this.state.addressShippoTo;
                        aux.street1 = e.target.value;
                        this._isMounted &&
                          this.setState({ addressShippoTo: aux });
                      }}
                    />
                    <input
                      className="form-control modal-input"
                      name="name"
                      type="text"
                      placeholder="Floor,door,apt...*"
                      value={this.state.floor}
                      required
                      onChange={this.handleFloor}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      className="form-control modal-input"
                      name="name"
                      type="text"
                      placeholder="City"
                      value={this.state.addressShippoTo.city}
                      required
                      onChange={(e) => {
                        let aux = this.state.addressShippoTo;
                        aux.city = e.target.value;
                        this._isMounted &&
                          this.setState({ addressShippoTo: aux });
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      className="form-control modal-input"
                      name="name"
                      type="text"
                      placeholder="State"
                      value={this.state.addressShippoTo.state}
                      required
                      onChange={(e) => {
                        let aux = this.state.addressShippoTo;
                        aux.state = e.target.value;
                        this._isMounted &&
                          this.setState({ addressShippoTo: aux });
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      className="form-control modal-input"
                      name="name"
                      type="text"
                      placeholder="Two-Letter State"
                      value={this.state.addressShippoTo.state_tl}
                      required
                      onChange={(e) => {
                        let aux = this.state.addressShippoTo;
                        aux.state_tl = e.target.value;
                        this._isMounted &&
                          this.setState({ addressShippoTo: aux });
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      className="form-control modal-input"
                      name="name"
                      type="text"
                      placeholder="ZIP"
                      value={this.state.addressShippoTo.zip}
                      required
                      onChange={(e) => {
                        let aux = this.state.addressShippoTo;
                        aux.zip = e.target.value;
                        this._isMounted &&
                          this.setState({ addressShippoTo: aux });
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      className="form-control modal-input"
                      name="name"
                      type="text"
                      placeholder="Country"
                      value={this.state.addressShippoTo.country}
                      required
                      onChange={(e) => {
                        let aux = this.state.addressShippoTo;
                        aux.country = e.target.value;
                        this._isMounted &&
                          this.setState({ addressShippoTo: aux });
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      className="form-control modal-input"
                      name="name"
                      type="text"
                      placeholder="Two-Letter Country"
                      value={this.state.addressShippoTo.country_tl}
                      required
                      onChange={(e) => {
                        let aux = this.state.addressShippoTo;
                        aux.country_tl = e.target.value;
                        this._isMounted &&
                          this.setState({ addressShippoTo: aux });
                      }}
                    />
                  </div>

                  <div className="col-12">
                    <button
                      className="btn btn-secondary btn-menu btn-size"
                      type="submit"
                    >
                      Submit Address
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* END Form in case of autocomplete failed*/}
      </div>
    );
  }
}

export default PickPlacePage;
