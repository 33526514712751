import React, { Component } from "react";
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from "react-stripe-elements";
import "./save-card.css";
import { errorMessagesStripe } from "../../#constants";

class SaveCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completeCardNumber: false,
      completeName: false,
      completeExpiry: false,
      completeCVC: false,
      errorCardNumber: "",
      errorExpiry: "",
      errorCVC: "",
      errorSubmit: "",
      errorName: "",
      cardName: "",
      flip: false,
    };

    this.handleError = this.handleError.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.flipCard = this.flipCard.bind(this);
    this.submit = this.submit.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleComplete(e) {
    switch (e.elementType) {
      case "cardNumber":
        if (e.complete) {
          this._isMounted && this.setState({ completeCardNumber: true });
        } else {
          this._isMounted && this.setState({ completeCardNumber: false });
        }
        break;

      case "cardExpiry":
        if (e.complete) {
          this._isMounted && this.setState({ completeExpiry: true });
        } else {
          this._isMounted && this.setState({ completeExpiry: false });
        }
        break;

      case "cardCvc":
        if (e.complete) {
          this._isMounted && this.setState({ completeCVC: true });
        } else {
          this._isMounted && this.setState({ completeCVC: false });
        }
        break;
      default:
        break;
    }
  }

  handleError(e) {
    switch (e.elementType) {
      case "cardNumber":
        if (e.error !== undefined) {
          this._isMounted &&
            this.setState({
              errorCardNumber: errorMessagesStripe[e.error.code],
            });
        } else {
          this._isMounted && this.setState({ errorCardNumber: "" });
        }

        break;

      case "cardExpiry":
        if (e.error !== undefined) {
          this._isMounted &&
            this.setState({ errorExpiry: errorMessagesStripe[e.error.code] });
        } else {
          this._isMounted && this.setState({ errorExpiry: "" });
        }

        break;

      case "cardCvc":
        if (e.error !== undefined) {
          this._isMounted &&
            this.setState({ errorCVC: errorMessagesStripe[e.error.code] });
        } else {
          this._isMounted && this.setState({ errorCVC: "" });
        }
        break;
      default:
        break;
    }
  }

  async submit() {
    this._isMounted && this.setState({ errorSubmit: "" });
    try {
      let { token } = await this.props.stripe.createToken({
        name: this.state.cardName,
      });
      this.props.getData(token);
    } catch (error) {
      this._isMounted && this.setState({ errorSubmit: error.message });
    }
  }

  flipCard() {
    if (!this.state.flip) {
      this._isMounted && this.setState({ flip: true });
      document.querySelector(".flip-card").classList.add("hovered");
    } else {
      this._isMounted && this.setState({ flip: false });
      document.querySelector(".flip-card").classList.remove("hovered");
    }
  }

  render() {
    const styleCardNumber = {
      base: {
        color: "black",
        fontSize: "20px",
        fontWeight: "700",
        fontFamily: '"Open Sans", sans-serif',
        "::placeholder": {
          color: "#00000080",
          fontSize: "20px",
          letterSpacing: "4px",
        },
      },
    };
    const styleCardExpiryElement = {
      base: {
        color: "black",
        fontSize: "16px",
        fontWeight: "600",
        fontFamily: '"Open Sans", sans-serif',
        "::placeholder": {
          color: "#00000080",
          fontSize: "18px",
          letterSpacing: "4px",
        },
      },
    };
    return (
      <div className="Cards-Submit">
        <div className="col-12 col-lg-3 p-0">
          <label
            style={{
              fontSize: 14,
              color: "#999",
              fontWeight: "bold",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            Enter Card Number Below
          </label>
          <CardNumberElement
            style={styleCardNumber}
            className="card-number input-borders"
            placeholder="•••• •••• •••• ••••"
            onChange={(e) => {
              this.handleComplete(e);
              this.handleError(e);
            }}
          />
        </div>
        <div className="col-12 col-lg-3 p-0">
          <label
            style={{
              fontSize: 14,
              color: "#999",
              fontWeight: "bold",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            Enter Card Name Below
          </label>
          <br />
          <input
            style={{
              width: "100%",
              color: "black",
              fontSize: "20px",
              fontWeight: "700",
              fontFamily: '"Open Sans", sans-serif',
            }}
            type="text"
            placeholder="•••••••• ••••••••"
            onChange={(e) => {
              this._isMounted && this.setState({ cardName: e.target.value });
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                this._isMounted &&
                  this.setState({
                    errorName: "The cardholder's name is missing",
                    completeName: false,
                  });
              } else {
                this._isMounted &&
                  this.setState({ errorName: "", completeName: true });
              }
            }}
          />
        </div>
        <div className="card-date col-12 col-lg-3 p-0">
          <label
            style={{
              fontSize: 14,
              color: "#999",
              fontWeight: "bold",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            Enter Expiration Day Below
          </label>
          <CardExpiryElement
            style={styleCardExpiryElement}
            className="card-expiry input-borders"
            placeholder="••/••"
            onChange={(e) => {
              this.handleComplete(e);
              this.handleError(e);
            }}
          />
        </div>
        <div className="col-12 col-lg-3 p-0">
          <label
            style={{
              fontSize: 14,
              color: "#999",
              fontWeight: "bold",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            Enter CVC Below
          </label>
          <CardCVCElement
            className="styleCardNumber input-borders"
            placeholder="••••"
            onChange={(e) => {
              this.handleComplete(e);
              this.handleError(e);
            }}
          />
        </div>
        <div className="box-errors">
          {this.state.errorCardNumber !== "" && (
            <p className="error-card">{this.state.errorCardNumber}</p>
          )}
          {this.state.errorName !== "" && (
            <p className="error-card">{this.state.errorName}</p>
          )}
          {this.state.errorExpiry !== "" && (
            <p className="error-card">{this.state.errorExpiry}</p>
          )}
          {this.state.errorCVC !== "" && (
            <p className="error-card">{this.state.errorCVC}</p>
          )}
          {this.state.errorSubmit !== "" && (
            <p className="error-card">{this.state.errorSubmit}</p>
          )}
        </div>

        <div
          className="row"
          style={{
            marginTop: 40,
          }}
        >
          <div className="col-12 col-md-6">
            <button
              className="btn btn-secondary btn-new-card"
              onClick={() => {
                this.submit();
              }}
              disabled={
                !this.state.completeCardNumber ||
                !this.state.completeName ||
                !this.state.completeExpiry ||
                !this.state.completeCVC
              }
            >
              CONTINUE BY ADDING A BILLING ADDRESS
            </button>
          </div>

          <div className="col-12 col-md-6">
            <button
              className="btn-new-card"
              onClick={() => {
                this.props.back();
              }}
            >
              GO BACK
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default injectStripe(SaveCard);
