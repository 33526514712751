import React, { Component } from "react";
import "./portrait-text.css";

class PortraitText extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="Portrait">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="intro-portrait">
                  OUR TERMS OF USE WERE RECENTLY UPDATED. PLEASE REVIEW THEM
                  CAREFULLY.
                </p>
                <h1 className="title-portrait">ArtLife TERMS OF USE</h1>
                <p className="text-portrait">
                  Welcome to ArtLife. These Terms of Use ("Terms") are an
                  agreement between you ("you" or "your") and ArtLife, Inc.
                  d/b/a ArtLife ("ArtLife" or "we" or "our" or "us") that
                  governs your access to and use of our website, products, and
                  services (collectively, "Services"). By accessing or using our
                  Services in any way, you agree to these Terms. If you do not
                  agree to these Terms, you are not permitted to access or use
                  our Services. Please read these Terms carefully, and feel free
                  to contact us if you have any questions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PortraitText;
