/* eslint-disable */
//Dependencies
import React, { Component } from "react";
import "./new-address.css";
import Loader from "react-loader-spinner";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { dbFirestore, auth } from "../../@firebase";
import { Redirect } from "react-router-dom";
import * as firebase from "firebase/app";
import { MyMapComponent, metasTags } from "../../#constants";
require("firebase/functions");

class NewAddressPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMarkerShown: false,
      address: "",
      markerLog: 2.4597668,
      markerLat: 48.8152937,
      user: {},
      logged: false,
      fullname: "",
      floor: "",
      addressShippoTo: {
        street1: "",
        city: "",
        state: "",
        state_tl: "",
        zip: "",
        country: "",
        country_tl: "",
        phone: "",
      },
      existMapJson: false,
      visible: false,
      redirectAuction: false,
      redirect: false,
      lot: "",
      redirectLog: false,
      visibleLoader: false,
      error: "",
      checkout: false,
      bidSelected: "",
      auctionData: {},
      stripeData: {},
    };
    this.handleFloor = this.handleFloor.bind(this);
    this.handleName = this.handleName.bind(this);
    this.backCards = this.backCards.bind(this);
    this.stripeResponseHandler = this.stripeResponseHandler.bind(this);
    this.addCard = this.addCard.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.verifyAddress = this.verifyAddress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.init = this.init.bind(this);
    this.setProps = this.setProps.bind(this);
    this.stripeResponseHandler = this.stripeResponseHandler.bind(this);
    this._isMounted = false;
  }

  async stripeResponseHandler() {
    var addCreditCart = firebase.functions().httpsCallable("addCard");
    let addressShippoTo = this.state.addressShippoTo;
    addressShippoTo.street1 = addressShippoTo.street1 + " " + this.state.floor;
    try {
      let result = await addCreditCart({
        customer_id: this.state.infoUser.customer_id,
        token: this.state.stripeData.id,
        email: this.state.infoUser.email,
      });
      var updateBillingAddress = firebase
        .functions()
        .httpsCallable("updateBillingAddress");
      if (this.state.infoUser.customer_id === "") {
        await dbFirestore.collection("users").doc(this.state.user.uid).update({
          customer_id: result.data.id,
          default_card: result.data.default_source,
        });
        await updateBillingAddress({
          customer_id: result.data.id,
          card_id: result.data.default_source,
          name: result.data.name,
          address: addressShippoTo,
        });
        if (this.props.location.state !== undefined) {
          if (this.props.location.state.data.auction) {
            this.backCards(true);
          } else {
            this.backCards(false);
          }
        } else {
          this.backCards(false);
        }
      } else {
        await updateBillingAddress({
          customer_id: this.state.infoUser.customer_id,
          card_id: result.data.id,
          name: result.data.name,
          address: addressShippoTo,
        });
        if (this.props.location.state !== undefined) {
          if (this.props.location.state.data.auction) {
            this.backCards(true);
          } else {
            this.backCards(false);
          }
        } else {
          this.backCards(false);
        }
      }
    } catch (error) {
      this._isMounted &&
        this.setState({
          visible: false,
          visibleLoader: false,
          error:
            error.message + " Please contact your bank for more information.",
        });
      console.error("Error desde la funcion de agregar tarjeta", error);
      if (document.getElementById("newAddBTN")) {
        document.getElementById("newAddBTN").removeAttribute("disabled");
      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    metasTags.setMetaTags();
    this.setProps();
    auth.onAuthStateChanged((user) => {
      if (user) {
        var userLogged = auth.currentUser;
        this._isMounted &&
          this.setState({ user: userLogged, logged: true, redirectLog: false });
        this.init();
      } else {
        this._isMounted &&
          this.setState({
            user: {},
            logged: false,
            redirectLog: true,
            infoUser: {},
          });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async init() {
    let snapshot = await dbFirestore
      .collection("users")
      .doc(auth.currentUser.uid)
      .get();
    if (snapshot.exists) {
      this._isMounted && this.setState({ infoUser: snapshot.data() });
      if (snapshot.data().mapJson !== "") {
        let region = {
          latitude: snapshot.data().mapJson.location.lat,
          longitude: snapshot.data().mapJson.location.lng,
          latitudeDelta: 0.1,
          longitudeDelta: 0.05,
        };
        this._isMounted &&
          this.setState({
            existMapJson: true,
            addressShippoTo: snapshot.data().mapJson.addressShippoTo,
            address: snapshot.data().mapJson.address,
            region: region,
          });
      }
    }
  }

  setProps() {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.data.auction) {
        this._isMounted &&
          this.setState({
            auction: true,
            auctionData: this.props.location.state.data.auctionData,
            lot: this.props.location.state.data.lot,
            bidSelected: this.props.location.state.data.bidSelected,
          });
      }
      if (this.props.location.state.data.checkout) {
        this._isMounted && this.setState({ checkout: true });
      }

      this._isMounted &&
        this.setState({
          stripeData: this.props.location.state.data.stripeData,
        });
    } else {
      //sin props se debe mandar al home
      this._isMounted && this.setState({ redirectLog: true });
    }
  }

  async addCard() {
    this._isMounted && this.setState({ visibleLoader: true });
    this.stripeResponseHandler();
  }

  async backCards(op) {
    if (document.getElementById("newAddBTN")) {
      document.getElementById("newAddBTN").removeAttribute("disabled");
    }
    if (op) {
      //auctions
      this._isMounted &&
        this.setState({ redirectAuction: true, visibleLoader: false });
    } else {
      this._isMounted &&
        this.setState({ redirect: true, visibleLoader: false });
    }
  }

  //React auto complete
  handleChange(address) {
    this._isMounted && this.setState({ address });
  }

  async handleSelect(address) {
    try {
      var result = await geocodeByAddress(address);
      var latlng = await getLatLng(result[0]);
      this._isMounted &&
        this.setState({
          isMarkerShown: true,
          markerLog: latlng.lng,
          markerLat: latlng.lat,
        });
      let jsonAddress = {
        name: this.state.infoUser.name,
        email: this.state.infoUser.email,
        street1: "",
        city: "",
        state: "",
        state_tl: "",
        zip: "",
        country: "",
        country_tl: "",
        phone: "",
      };
      result[0].address_components.forEach((value) => {
        var x = value.types[0];
        switch (x) {
          case "street_number":
            jsonAddress.street1 = value.long_name;
            break;
          case "route":
            if (jsonAddress.street1 === "")
              jsonAddress.street1 = value.long_name;
            else
              jsonAddress.street1 = jsonAddress.street1 + " " + value.long_name;
            break;
          case "locality":
            jsonAddress.city = value.long_name;
            break;
          case "administrative_area_level_1":
            jsonAddress.state = value.long_name;
            jsonAddress.state_tl = value.short_name;
            break;
          case "postal_code":
            jsonAddress.zip = value.long_name;
            break;
          case "country":
            jsonAddress.country = value.long_name;
            jsonAddress.country_tl = value.short_name;
            break;

          default:
            break;
        }
      });
      this._isMounted &&
        this.setState({
          addressShippoTo: jsonAddress,
          address: result[0].formatted_address,
        });
    } catch (error) {
      console.error(error);
    }
  }

  handleFloor(e) {
    this._isMounted && this.setState({ floor: e.target.value });
  }

  handleName(e) {
    this._isMounted && this.setState({ fullname: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    var data = this.state.addressShippoTo;
    data.name = this.state.infoUser.name;
    data.email = this.state.infoUser.email;
    this._isMounted && this.setState({ addressShippoTo: data });
    this.addCard();
    document.getElementById("modal-review-close").click();
  }

  verifyAddress() {
    const data = this.state.addressShippoTo;
    if (
      data.street1 !== "" &&
      data.city !== "" &&
      data.state !== "" &&
      data.state_tl !== "" &&
      data.zip !== "" &&
      data.country !== "" &&
      data.country_tl !== ""
    ) {
      this.addCard();
    } else {
      document.getElementById("btn-modal").click();
    }
  }

  render() {
    const isMarkerShown = this.state.isMarkerShown;
    const markerLog = this.state.markerLog;
    const markerLat = this.state.markerLat;

    return (
      <div className="NewAddress">
        {this.state.visibleLoader && (
          <div className="loader-area">
            <div className="loader-box">
              <Loader type="Oval" color="#000" height="50" width="50" />
            </div>
          </div>
        )}
        <div className="container-fluid no-pd-mobile">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="title-new-add text-center closit_text my-3">
                  New shipping address
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <MyMapComponent
                  isMarkerShown={isMarkerShown}
                  markerLat={markerLat}
                  markerLog={markerLog}
                />
              </div>
            </div>
            <div className="row  my-3">
              <div className="col-12">
                <form className="form-address">
                  <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Street Address or Place...*",
                            className:
                              "location-search-input location-form-input form-control",
                          })}
                          value={this.state.address}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                  <input
                    className="form-control location-form-input"
                    onChange={this.handleFloor}
                    type="text"
                    name="location"
                    placeholder="Optional: floor, door, apt..."
                  />
                  {/* <input
                    className="form-control location-form-input"
                    onChange={this.handleName}
                    type="text"
                    name="location"
                    placeholder="Full Name"
                    required
                  /> */}
                </form>
              </div>
              <div
                className="col-12 col-md-6"
                style={{
                  marginTop: 10,
                }}
              >
                <button
                  id="newAddBTN"
                  className="btn btn-secondary btn-new-add-map"
                  onClick={(e) => {
                    this.verifyAddress(e);
                  }}
                  disabled={
                    this.state.address === ""
                    // this.state.floor === "" ||
                    // this.state.fullname === ""
                  }
                >
                  Save new shipping address
                </button>
              </div>
              <div
                className="col-12 col-md-6"
                style={{
                  marginTop: 10,
                }}
              >
                <button
                  className="btn btn-secondary btn-new-add-map"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  GO BACK
                </button>
              </div>
            </div>
            <p className="address-error-text text-center">{this.state.error}</p>
          </div>
        </div>
        {this.state.redirect && (
          <Redirect
            push
            to={{
              pathname: "/cards",
              state: {
                data: {
                  address: true,
                  checkout: this.state.checkout,
                },
              },
            }}
          />
        )}
        {this.state.redirectAuction && (
          <Redirect
            push
            to={{
              //pathname: "/lot/" + this.state.lot,
              pathname: "/cards/",
              state: {
                data: {
                  auction: true,
                  lot: this.state.lot,
                  bidSelected: this.state.bidSelected,
                  auctionData: this.state.auctionData,
                },
              },
            }}
          />
        )}
        {this.state.redirectLog && <Redirect push to={"/"} />}

        {/*BEGIN Form in case of autocomplete failed*/}
        <button
          type="button"
          className="btn btn-primary d-none"
          data-toggle="modal"
          data-target="#completeManual"
          id="btn-modal"
        />
        <div
          className="modal fade"
          id="completeManual"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="completeManualTitle"
          aria-hidden="true"
        >
          <div
            className="container modal-dialog modal-dialog-centered"
            role="document"
          >
            <div className="modal-content star-modal">
              <button
                type="button"
                className="close x"
                data-dismiss="modal"
                aria-label="Close"
                id="modal-review-close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-header">
                <div className="col-12 col-lg-12">
                  <p
                    className="modal-title star-title_container mt-3"
                    id="completeManualTitle"
                  >
                    We have detected that our autocomplete did not obtain all
                    the data we need please enter it here.
                  </p>
                </div>
              </div>
              <div className="modal-body">
                <form onSubmit={this.handleSubmit}>
                  <div className="col-12">
                    <input
                      className="form-control modal-input"
                      name="name"
                      type="text"
                      placeholder="Street"
                      value={this.state.addressShippoTo.street1}
                      required
                      onChange={(e) => {
                        let aux = this.state.addressShippoTo;
                        aux.street1 = e.target.value;
                        this.setState({ addressShippoTo: aux });
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      className="form-control modal-input"
                      name="name"
                      type="text"
                      placeholder="City"
                      value={this.state.addressShippoTo.city}
                      required
                      onChange={(e) => {
                        let aux = this.state.addressShippoTo;
                        aux.city = e.target.value;
                        this.setState({ addressShippoTo: aux });
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      className="form-control modal-input"
                      name="name"
                      type="text"
                      placeholder="State"
                      value={this.state.addressShippoTo.state}
                      required
                      onChange={(e) => {
                        let aux = this.state.addressShippoTo;
                        aux.state = e.target.value;
                        this.setState({ addressShippoTo: aux });
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      className="form-control modal-input"
                      name="name"
                      type="text"
                      placeholder="Two-Letter State"
                      value={this.state.addressShippoTo.state_tl}
                      required
                      onChange={(e) => {
                        let aux = this.state.addressShippoTo;
                        aux.state_tl = e.target.value;
                        this.setState({ addressShippoTo: aux });
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      className="form-control modal-input"
                      name="name"
                      type="text"
                      placeholder="ZIP"
                      value={this.state.addressShippoTo.zip}
                      required
                      onChange={(e) => {
                        let aux = this.state.addressShippoTo;
                        aux.zip = e.target.value;
                        this.setState({ addressShippoTo: aux });
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      className="form-control modal-input"
                      name="name"
                      type="text"
                      placeholder="Country"
                      value={this.state.addressShippoTo.country}
                      required
                      onChange={(e) => {
                        let aux = this.state.addressShippoTo;
                        aux.country = e.target.value;
                        this.setState({ addressShippoTo: aux });
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      className="form-control modal-input"
                      name="name"
                      type="text"
                      placeholder="Two-Letter Country"
                      value={this.state.addressShippoTo.country_tl}
                      required
                      onChange={(e) => {
                        let aux = this.state.addressShippoTo;
                        aux.country_tl = e.target.value;
                        this.setState({ addressShippoTo: aux });
                      }}
                    />
                  </div>

                  <div className="col-12">
                    <button
                      className="btn btn-secondary btn-menu btn-size"
                      type="submit"
                    >
                      Submit Address
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* END Form in case of autocomplete failed*/}
      </div>
    );
  }
}

export default NewAddressPage;
