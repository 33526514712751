import React, { Component } from "react";
import { dbFirestore, timestamp } from "../../@firebase";
import "./condition-modal.css";
import $ from "jquery";

class ConditionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      success: "",
    };
    this._isMounted = false;
    this.timeout = 0;
  }

  onSubmit = async () => {
    if (
      this.state.firstName !== "" ||
      this.state.lastName !== "" ||
      this.state.email !== ""
    )
      await dbFirestore
        .collection("condition_report")
        .add({
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          id: this.props.LotId,
          title: this.props.title,
          createdAt: timestamp,
          uid: this.props.userId,
        })
        .then((docRef) =>
          dbFirestore.collection("condition_report").doc(docRef.id).update({
            reportNumber: docRef.id,
          })
        );

    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      success: "Your request has been made",
    });

    this.timeout = setTimeout(() => $("#conditionModal").modal("hide"), 2000);
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.timeout);
  }
  render() {
    return (
      <div
        className="modal fade"
        id="conditionModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="conditionModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered" role="document">
          <div className="modal-content condition_modal">
            <div className="text-center p-3">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="condition_modal_x" aria-hidden="true">
                  {" "}
                  &times;
                </span>
              </button>
              <h5 id="conditionModalLabel" className="closit_text">
                Contact us
              </h5>
            </div>
            <div className="modal-body text-center share-modal-body">
              <div className="container">
                <div className="row align-items-center justify-content-center mb-3">
                  {/* <div className="col-2">
                    <span className="no_hover">
                      <i className="fas fa-user"></i>
                    </span>
                  </div> */}
                  <div className="col-10">
                    <input
                      className="form-control new-input"
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      value={this.state.firstName}
                      onChange={(e) => {
                        this.setState({ firstName: e.target.value });
                      }}
                      onBlur={this.handleBlurSend}
                      required
                    />
                  </div>
                </div>

                <div className="row align-items-center justify-content-center mb-3">
                  {/* <div className="col-2">
                    <span className="no_hover">
                      <i className="fas fa-user"></i>
                    </span>
                  </div> */}
                  <div className="col-10">
                    <input
                      className="form-control new-input"
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      value={this.state.lastName}
                      onChange={(e) => {
                        this.setState({ lastName: e.target.value });
                      }}
                      onBlur={this.handleBlurSend}
                      required
                    />
                  </div>
                </div>

                <div className="row align-items-center justify-content-center mb-3">
                  {/* <div className="col-2">
                    <span className="no_hover">
                      <i className="fas fa-envelope" />
                    </span>
                  </div> */}
                  <div className="col-10">
                    <input
                      className="form-control new-input"
                      type="email"
                      name="email"
                      placeholder="Enter your email address"
                      value={this.state.email}
                      onChange={(e) => {
                        this.setState({ email: e.target.value });
                      }}
                      onBlur={this.handleBlurSend}
                      required
                    />
                  </div>
                </div>

                <div className="row align-items-center justify-content-center mb-3">
                  <div className="col-8">
                    <button
                      className="btn btn-secondary btn-sm btn-lot-detail"
                      style={{
                        marginTop: 10,
                      }}
                      data-target="#conditionModal"
                      onClick={() => this.onSubmit()}
                    >
                      REQUEST CONDITION REPORT
                    </button>

                    {this.state.success && (
                      <p className="sucess mt-2 mb-0">{this.state.success}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ConditionModal;
