//Dependencies
import React, { Component } from "react";
import { dbFirestore, auth } from "../../@firebase";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import {
  services,
  metasTags,
  formatNumber,
  calculateNewPrice,
} from "../../#constants";
import "./history-detail.css";

class HistoryDetailPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        id: "",
        items: "",
        subtotal: "",
        shipping: "",
        total: "",
        name: "",
        addressShippoTo: "",
        card: "",
        discounts: {},
      },
      order: [],
      user: {},
      logged: false,
      redirect: false,
      redirect404: false,
      discountS: false,
      discountT: false,
      discountP: false,
    };
    this._isMounted = false;
    this.init = this.init.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    window.scrollTo(0, 0);
    metasTags.setMetaTags();
    auth.onAuthStateChanged((user) => {
      if (user) {
        var userLogged = auth.currentUser;
        this._isMounted &&
          this.setState({ user: userLogged, logged: true, redirect: false });
        this.init();
      } else {
        this._isMounted &&
          this.setState({ user: {}, logged: false, redirect: true });
      }
    });
  }

  async init() {
    let snapshot = await dbFirestore
      .collection("orders")
      .doc(this.props.match.params.id)
      .get();
    if (snapshot.exists) {
      let data = snapshot.data();
      let order = [{ data: data.dataOrder }];
      this._isMounted &&
        this.setState({ data: data, order: order }, async () => {
          ///Promotionals code
          if (
            this.state.data.promotionalDiscounts !== undefined &&
            this.state.data.promotionalDiscounts.length > 0
          ) {
            let snapshot = await dbFirestore
              .collection("promotional_banner")
              .get();
            if (!snapshot.empty) {
              var promotions = [];
              await services.asyncForEach(snapshot.docs, (promotion) => {
                promotions.push(promotion.data());
              });

              for (
                var i = 0;
                i < this.state.data.promotionalDiscounts.length;
                i++
              ) {
                const data = this.state.data.promotionalDiscounts[i];
                let exist = promotions.find((value) => value.code === data);
                if (exist !== undefined) {
                  if (exist.type === "shipping") {
                    this._isMounted && this.setState({ discountS: true });
                  } else if (exist.type === "taxes") {
                    this._isMounted && this.setState({ discountT: true });
                  } else {
                    this._isMounted && this.setState({ discountP: true });
                  }
                }
              }
            }
          }
          /// END Promotionals code

          /// Rewards code
          if (
            this.state.data.rewardDiscounts !== undefined &&
            this.state.data.rewardDiscounts.length > 0
          ) {
            for (var j = 0; j < this.state.data.rewardDiscounts.length; j++) {
              let reward = this.state.data.rewardDiscounts[j].split("-")[0];
              let snapshot = await dbFirestore
                .collection("rewards")
                .doc(reward)
                .get();
              if (snapshot.exists) {
                if (snapshot.data().type === "shipping") {
                  this._isMounted && this.setState({ discountS: true });
                } else if (snapshot.data().type === "taxes") {
                  this._isMounted && this.setState({ discountT: true });
                } else {
                  this._isMounted && this.setState({ discountP: true });
                }
              }
            }
          }
          // END Rewards code
        });
    } else {
      this._isMounted && this.setState({ redirect404: true });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  renderItems() {
    if (this.state.data !== null && this.state.data !== undefined) {
      if (
        this.state.data.dataOrder !== null &&
        this.state.data.dataOrder !== undefined
      ) {
        let prods = [];
        for (var i = 0; i < this.state.data.dataOrder.length; i++) {
          let sizeSelected = this.state.data.dataOrder[i].dataCart.size;
          let prodData = this.state.data.dataOrder[i].dataProduct.sizes.find(
            (x) => x.label === sizeSelected
          );

          prods.push(
            <div
              className="col-12 col-lg-6 no-pd-mobile mb-20"
              key={"prod-" + i}
            >
              <div className="card order-card h-100">
                <div className="card-body order-card-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-6 col-md-4">
                        <Link
                          to={
                            "/product/" +
                            this.state.data.dataOrder[i].dataProduct.id
                          }
                        >
                          <img
                            className="img-order-expanded"
                            src={
                              this.state.data.dataOrder[i].dataProduct
                                .principalImage
                            }
                            alt={this.state.data.dataOrder[i].dataProduct.title}
                          />
                        </Link>
                        <p className="closit_text item-order-title">
                          {this.state.data.dataOrder[i].dataProduct.title}
                        </p>
                        {this.state.data.dataOrder[i].dataProduct.sale && (
                          <p className="closit_text item-order-price">
                            <span className="marked-price">
                              ${formatNumber(prodData.price)}
                            </span>
                            $
                            {formatNumber(
                              calculateNewPrice(
                                prodData.price,
                                this.state.data.dataOrder[i].dataProduct
                                  .discountSale
                              )
                            )}
                          </p>
                        )}
                        {!this.state.data.dataOrder[i].dataProduct.sale && (
                          <p className="closit_text item-order-price">
                            ${formatNumber(prodData.price)}
                          </p>
                        )}
                      </div>
                      <div className="col-6 col-md-8">
                        <p className="title-order-final closit_text">
                          Shipping (
                          {this.state.data.dataOrder[i].dataCart.quantity}) item
                          from (
                          {
                            this.state.data.dataOrder[i].dataProduct
                              .addressShippoOrigin.country
                          }
                          )
                        </p>
                        <p className="closit_text">
                          Shipping $
                          {formatNumber(
                            this.state.data.dataOrder[i].dataCart.shipping
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
        return prods;
      }
    }
  }

  render() {
    return (
      <div className="HistoryDetail">
        {this.state.redirect && <Redirect push to={"/cart"} />}
        {this.state.redirect404 && <Redirect push to={"/404"} />}
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="title-order text-center my-3 closit_text">
                  Order Information
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p className="text-center icon-check">
                  <i className="fas fa-check-circle" />
                </p>
                <p className="text-center">
                  Thank you for placing your order. You will receive
                  confirmation by email email soon.
                </p>
                <p className="text-center order-number-title closit_text">
                  Order Number
                </p>
                <p className="text-center">{this.state.data.id}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid no-pd-mobile">
          <div className="container">
            <div className="row">
              <div className="col-12 no-pd-mobile">
                <div className="card order-card">
                  <div className="card-body order-card-body">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-6 first-col">
                          <p className="order-text">
                            <span className="closit_text">Items:</span>{" "}
                            {this.state.data.items}
                          </p>
                          <p className="order-text">
                            <span className="closit_text">Subtotal:</span> $
                            {formatNumber(this.state.data.subtotal)}{" "}
                            {this.state.discountP && (
                              <span className="label-discount">*</span>
                            )}
                          </p>
                          <p className="order-text">
                            <span className="closit_text">Shipping:</span> $
                            {formatNumber(this.state.data.shipping)}{" "}
                            {this.state.discountS && (
                              <span className="label-discount">*</span>
                            )}
                          </p>
                        </div>
                        <div className="col-6 second-col">
                          <p className="order-text">
                            <span className="closit_text">Total:</span> $
                            {formatNumber(this.state.data.total)}{" "}
                            {this.state.discountT && (
                              <span className="label-discount">*</span>
                            )}
                          </p>
                        </div>
                        <div className="col-12">
                          {(this.state.discountP ||
                            this.state.discountS ||
                            this.state.discountT) && (
                            <p className="text-discount">
                              <span className="label-discount">*</span> Discount
                              was applied.
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 no-pd-mobile">
                <div className="card order-card">
                  <div className="card-body order-card-body">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-6 first-col">
                          <p className="title-order-final closit_text">
                            Shipping Address:
                          </p>
                          <p className="order-text">
                            {this.state.data.addressShippoTo.name}
                          </p>
                          <p className="order-text">
                            {this.state.data.addressShippoTo.street1}
                          </p>
                          <p className="order-text">
                            {this.state.data.addressShippoTo.city}
                          </p>
                          <p className="order-text">
                            {this.state.data.addressShippoTo.zip}
                          </p>
                          <p className="order-text">
                            {this.state.data.addressShippoTo.country}
                          </p>
                        </div>
                        <div className="col-6 second-col">
                          <p className="title-order-final closit_text">
                            Card Charge:
                          </p>
                          <p className="order-text">
                            {this.state.data.card.brand}{" "}
                            {this.state.data.card.last4}
                          </p>
                          <p className="order-text">
                            {this.state.data.card.exp_month}-
                            {this.state.data.card.exp_year}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid no-pd-mobile">
          <div className="container">
            <div className="row">{this.renderItems()}</div>
          </div>
        </div>
        <div className="container-fluid no-pd-mobile">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <button
                  className="btn btn-secondary btn-back-order"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  GO BACK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HistoryDetailPage;
