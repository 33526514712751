//Dependencies
import React, { Component } from "react";
import { dbFirestore, auth } from "../../@firebase/index";
import { Redirect } from "react-router-dom";
import "./delivery.css";
import Loader from "react-loader-spinner";
import { metasTags, formatNumber } from "../../#constants";
import * as firebase from "firebase/app";
require("firebase/functions");

class DeliveryPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address: "",
      isMounted: false,
      data: [],
      infoUser: {},
      redirect: false,
      visibleLoader: true,
      noShippo: true,
      errorMessage: "",
    };
    this.dismarkAll = this.dismarkAll.bind(this);
    this.init = this.init.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    metasTags.setMetaTags();
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.init();
      } else {
        this._isMounted && this.setState({ redirect: true, infoUser: {} });
      }
    });
  }

  async init() {
    try {
      let snapshot = await dbFirestore
        .collection("users")
        .doc(auth.currentUser.uid)
        .get();
      let infoUser = snapshot.data();
      let address = infoUser.address;
      let existAddress = false;
      let addressShippoTo = {};
      this._isMounted && this.setState({ infoUser: infoUser });

      if (address.length > 0) {
        for (let i = 0; i < address.length; i++) {
          if (address[i].default === true) {
            addressShippoTo = address[i].addressShippoTo;
            existAddress = true;
            break;
          }
        }
      }

      let cart = infoUser.cart;

      if (cart.length > 0 && existAddress) {
        var shippoShipment = firebase
          .functions()
          .httpsCallable("shippoShipment");
        try {
          let result = await shippoShipment({
            addressTo: addressShippoTo,
            cart: cart,
          });
          this._isMounted &&
            this.setState({ data: result.data }, () => {
              this._isMounted &&
                this.setState({ visibleLoader: false, noShippo: false });
            });
        } catch (error) {
          if (error.details) {
            this._isMounted &&
              this.setState({
                noShippo: true,
                visibleLoader: false,
                errorMessage: error.message,
              });
          } else {
            this._isMounted &&
              this.setState({ noShippo: true, visibleLoader: false });
          }
        }
      } else {
        this._isMounted &&
          this.setState({ noShippo: true, visibleLoader: false });
      }
    } catch (error) {
      console.error(error);
      this._isMounted &&
        this.setState({ noShippo: true, visibleLoader: false });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  selectShippingDefault = async (event, item, indice) => {
    const target = event.target;
    //const value = target.type === 'checkbox' ? target.checked : target.value;
    const value = target.checked;

    var cart = this.state.infoUser.cart;
    var actualData = this.state.data;
    var tam = cart.length;
    var i;

    if (!value) {
      actualData[item.indice].default = "";
      this._isMounted &&
        this.setState({ data: actualData }, () => {
          actualData[item.indice].default = indice;
          this._isMounted && this.setState({ data: actualData });
        });
    } else {
      for (i = 0; i < tam; i++) {
        if (cart[i].id === item.dataProduct.id) {
          cart[i].shippingID = indice;
          cart[i].shipping = item.shippoShipment.rates[indice].amount;
          cart[i].dataShipping = item.shippoShipment.rates[indice];
          cart[i].object_id = item.shippoShipment.object_id;
          break;
        }
      }
      try {
        await dbFirestore
          .collection("users")
          .doc(this.state.infoUser.id)
          .update({ cart: cart });
        actualData[item.indice].default = indice;
        this.dismarkAll(indice, item.indice);
        this._isMounted && this.setState({ data: actualData });
      } catch (error) {
        console.error("Error ", error);
      }
    }
  };

  dismarkAll(index, searchIndex) {
    document
      .querySelectorAll(`.row[index="${searchIndex}"] .switch input`)
      .forEach((value, i) => {
        if (index !== i) {
          value.checked = false;
        }
      });
  }

  renderItems = () => {
    if (this.state !== null && this.state !== undefined) {
      if (this.state.data !== null && this.state.data !== undefined) {
        if (this.state.data.length > 0) {
          let data = [];
          let key = 0;
          this.state.data.forEach((item) => {
            let rates = [];
            let itemKey = 0;

            item.shippoShipment.rates.forEach((val, i) => {
              rates.push(
                <div className="card delivery-card" key={"item-" + itemKey}>
                  <div className="card-body delivery-card-body">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-6 first-col-delivery">
                          <div className="top-delivery">
                            <p>
                              <span className="closit_text">Price:</span> $
                              {formatNumber(val.amount)}
                            </p>
                            <p>
                              <span className="closit_text">
                                Duration Terms:
                              </span>{" "}
                              {val.duration_terms}
                            </p>
                          </div>
                          <div className="bottom-delivery">
                            <p className="service-tag">
                              <span>{val.servicelevel.name}</span>
                            </p>
                          </div>
                        </div>
                        <div className="col-6 second-col-delivery">
                          <div className="top-delivery">
                            <label className="switch">
                              <input
                                type="checkbox"
                                defaultChecked={
                                  item.default === i ? true : false
                                }
                                onChange={(value) => {
                                  this.selectShippingDefault(value, item, i);
                                }}
                              />
                              <span className="slider round" />
                            </label>
                          </div>

                          <div className="bottom-delivery">
                            <p className="info-tags">
                              {" "}
                              <span>{val.provider}</span>
                            </p>
                            <p className="info-tags">
                              {" "}
                              <span>{val.attributes[0]}</span>
                            </p>
                            <p className="info-tags">
                              {" "}
                              <span>Estimated Days {val.estimated_days}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
              itemKey++;
            });
            data.push(
              <div className="row" key={"item-" + key} index={key}>
                <div className="col-12 mt-3">
                  <p className="name-product-delivery closit_text">
                    Product: {item.dataProduct.title}
                  </p>
                  {rates}
                </div>
              </div>
            );
            key++;
          });
          return data;
        }
      }
    }
  };

  render() {
    return (
      <div className="Delivery">
        {this.state.redirect && <Redirect push to={"/"} />}
        {this.state.visibleLoader && (
          <div className="loader-delivery">
            <Loader type="Oval" color="#000" height="50" width="50" />
          </div>
        )}
        <div className="container-fluid bg-white-intro-delivery">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="text-center title-delivery closit_text my-3">
                  Delivery Method
                </h1>
              </div>
              <div className="col-12">
                <p className="text-center intro-text-delivery">
                  Each ArtLife Partner ships directly to you - so you'll receive
                  more than one delivery from same country/region
                </p>
              </div>
              <div className="col-12 ">
                <button
                  className="btn btn-secondary btn-delivery-back "
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  GO BACK
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          {!this.state.noShippo && (
            <div className="container">{this.renderItems()}</div>
          )}
          {this.state.noShippo &&
            !this.state.visibleLoader &&
            this.state.errorMessage === "" && (
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <p className="text-center closit_text my-3">
                      We did not get a delivery method, please try it later.
                    </p>
                  </div>
                </div>
              </div>
            )}
          {this.state.noShippo &&
            !this.state.visibleLoader &&
            this.state.errorMessage !== "" && (
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <p className="text-center closit_text my-3">
                      {this.state.errorMessage}
                    </p>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default DeliveryPage;
