//Dependencies
import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import { dbFirestore, auth } from "../../@firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./nav.css";
import logo from "../../assets/logo.gif";
import LoginForm from "../login";

class Nav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      searchQuery: "",
      dataQuery: [],
      visible: false,
      images: [],
      isRefreshing: false,
      flag: true,
      formVisible: false,
      formType: "",
      logged: false,
      userData: [],
      user: {},
    };
    this.close = this.close.bind(this);
    this.signOut = this.signOut.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    // this.getRamdonImages = this.getRamdonImages.bind(this);
    this.isMenuOpen = this.isMenuOpen.bind(this);
  }

  componentDidMount() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        var userLogged = auth.currentUser;
        this.setState({ user: userLogged, logged: true });
        /* dbFirestore.collection("users").doc(userLogged.uid).onSnapshot( snap =>{
            self.setState({ userData: snap.data() });
          });*/
        let snap = await dbFirestore
          .collection("users")
          .doc(userLogged.uid)
          .get();
        this.setState({ userData: snap.data() });
      } else {
        this.setState({ user: {}, logged: false });
      }
    });
    // this.getRamdonImages();
  }

  signOut() {
    var self = this;
    auth
      .signOut()
      .then(function () {
        toast.info("We're login out of your account", {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          className: "notify",
          bodyClassName: "notify-body",
          position: "bottom-center",
          toastId: "logout",
        });
        self.setState({ user: {}, logged: false });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  displayForm(value) {
    this.setState({ formVisible: true, formType: value });
  }

  close() {
    this.setState({ formVisible: false });
  }

  closeMenu() {
    document.querySelector(".bm-cross-button button").click();
  }

  // async getRamdonImages() {
  //   let general = await dbFirestore
  //     .collection("categorys")
  //     .where("publish", "==", true)
  //     .where("typeOf", "==", "general")
  //     .orderBy("updatedAt", "desc")
  //     .limit(50)
  //     .get();
  //   let influencer = await dbFirestore
  //     .collection("categorys")
  //     .where("publish", "==", true)
  //     .where("typeOf", "==", "influencer")
  //     .orderBy("updatedAt", "desc")
  //     .limit(50)
  //     .get();
  //   let designer = await dbFirestore
  //     .collection("categorys")
  //     .where("publish", "==", true)
  //     .where("typeOf", "==", "designer")
  //     .orderBy("updatedAt", "desc")
  //     .limit(50)
  //     .get();

  //   //GENERAL
  //   var ramdomNumber;
  //   if (general.size > 0) {
  //     ramdomNumber = Math.floor(Math.random() * (general.size - 0) + 0);
  //     var imgG = general.docs[ramdomNumber].data().principalImage;
  //   }

  //   //INFLUENCER
  //   if (influencer.size > 0) {
  //     ramdomNumber = Math.floor(Math.random() * (influencer.size - 0) + 0);
  //     var imgI = influencer.docs[ramdomNumber].data().principalImage;
  //   }

  //   //DESIGNER
  //   if (designer.size > 0) {
  //     ramdomNumber = Math.floor(Math.random() * (designer.size - 0) + 0);
  //     var imgD = designer.docs[ramdomNumber].data().principalImage;
  //   }

  //   this.setState({ general: imgG, influencer: imgI, designer: imgD });
  // }

  createBadge() {
    if (this.state !== null && this.state !== undefined) {
      if (this.state.userData !== null && this.state.userData !== undefined) {
        if (
          this.state.userData.cart !== null &&
          this.state.userData.cart !== undefined
        ) {
          let badge = [];
          if (this.state.userData.cart.length > 0) {
            badge.push(
              <span className="bag-badge" key="badge">
                {this.state.userData.cart.length}
              </span>
            );
          }
          return badge;
        }
      }
    }
  }

  isMenuOpen(state) {
    if (state.isOpen) {
      document.querySelector("#root").classList.add("blockScroll");
    } else {
      document.querySelector("#root").classList.remove("blockScroll");
    }
    return state.isOpen;
  }

  async componentWillReceiveProps() {
    if (this.state.logged) {
      let snap = await dbFirestore
        .collection("users")
        .doc(this.state.user.uid)
        .get();
      this.setState({ userData: snap.data() });
    }
  }

  render() {
    // const designerStyle = {
    //   background:
    //     "linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),url(" +
    //     this.state.designer +
    //     ")"
    // };
    // const influencerStyle = {
    //   background:
    //     "linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),url(" +
    //     this.state.influencer +
    //     ")"
    // };
    // const generalStyle = {
    //   background:
    //     "linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),url(" +
    //     this.state.general +
    //     ")"
    // };

    return (
      <div className="general_navbar">
        {/* {this.state.logged && (
          <div className="fix">
            <div className="icon floating_icon">
              <NavLink to="/search" className="nav-item-icon">
                <i className="fas fa-search black" />
              </NavLink>
            </div>
          </div>
        )} */}

        {/* Sidebar */}
        {/* 
        <Menu className="Menu-closit" onStateChange={this.isMenuOpen}>
          <div>
            <ul className="navbar-nav navbar-menu navbar-menu-desk">
              <li className="nav-item active home-tab nav_li_item center_nav_li li_border center_top items-tgt">
                <p className="upperacase">Shop by</p>
                <p className="" onClick={this.closeMenu}>
                  X
                </p>
              </li>
              <li className="nav-item active home-tab nav_li_item center_nav_li li_border">
                <div className="row center_margin justify-content-center">
                  <p className="nav-item upperacase items-tgt">Gallery</p>
                  <i onClick={this.closeMenu} className="fas fa-times black" />
                </div>
              </li>
              <li className="nav-item active home-tab nav_li_item center_nav_li li_border ">
                <div className="row center_margin justify-content-center">
                  <NavLink
                    to="/journal"
                    className="nav-item upperacase items-tgt"
                    onClick={this.closeMenu}
                  >
                    Journal
                  </NavLink>
                  <i className="fas fa-angle-right " />
                </div>
              </li>
              {this.state.logged === true && (
                <li className="nav-item active home-tab nav_li_item center_nav_li li_border">
                  <div className="row center_margin justify-content-center">
                    <NavLink
                      to="/wishlist"
                      className="nav-item upperacase items-tgt"
                      onClick={this.closeMenu}
                    >
                      Wishlist
                    </NavLink>
                    <i className="fas fa-angle-right  black" />
                  </div>
                </li>
              )}
              <li className="nav-item active home-tab nav_li_item center_nav_li li_border ">
                <div className="row center_margin justify-content-center">
                  <NavLink
                    to="/auctions"
                    className="nav-item upperacase items-tgt"
                    onClick={this.closeMenu}
                  >
                    Auctions
                  </NavLink>
                  <i className="fas fa-angle-right  black" />
                </div>
              </li>
              <li className="nav-item active home-tab nav_li_item center_nav_li li_border ">
                <div className="row center_margin justify-content-center">
                  <NavLink
                    to="/viewing-rooms"
                    className="nav-item upperacase items-tgt"
                    onClick={this.closeMenu}
                  >
                    Viewing rooms
                  </NavLink>
                  <i className="fas fa-angle-right  black" />
                </div>
              </li>
              <li className="nav-item active home-tab nav_li_item center_nav_li li_border ">
                <div className="row center_margin justify-content-center">
                  <NavLink
                    to="/discover"
                    className="nav-item upperacase items-tgt"
                    onClick={this.closeMenu}
                  >
                    Discover
                  </NavLink>
                  <i className="fas fa-angle-right  black" />
                </div>
              </li>
              {!this.state.logged && (
                <li className="nav-item active home-tab nav_li_item center_nav_li li_border ">
                  <div
                    className="row center_margin justify-content-center"
                    onClick={this.closeMenu}
                  >
                    <a
                      className="log_btn upperacase items-tgt"
                      onClick={() => this.displayForm("login")}
                    >
                      Log In / Sign Up
                    </a>
                    <i className="fas fa-angle-right  black" />
                  </div>
                </li>
              )}
              {this.state.logged && (
                <li className="nav-item active home-tab nav_li_item center_nav_li li_border ">
                  <div
                    className="row center_margin justify-content-center"
                    onClick={this.closeMenu}
                  >
                    <a
                      className="log_btn upperacase items-tgt"
                      onClick={() => this.signOut()}
                    >
                      Sign Out
                    </a>
                    <i className="fas fa-angle-right  black" />
                  </div>
                </li>
              )}
            </ul>
          </div>
        </Menu> */}

        {/* Navbar */}

        <nav className="navbar navbar-expand-lg navbar-light nav-pad px-0">
          <div
            className="col-2 col-sm-1 col-md-1 col-lg-1 col-xl-1 nav_logo_container"
            style={{ maxWidth: 60 }}
          >
            <Link to={"/"} className="">
              <img src={logo} className="logo" alt="ArtLife" />
            </Link>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo03"
            aria-controls="navbarTogglerDemo03"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse col-lg-8 col-xl-5 p-0 nav_menu_container"
            id="navbarTogglerDemo03"
          >
            <ul className="navbar-nav mt-2 mt-lg-0">
              {/* <li className="nav-item active home-tab py-1 mb-2 py-lg-0 mb-lg-0 py-xl-0 mb-xl-0">
                <NavLink
                  to="/categories/artists"
                  className="nav-item"
                  onClick={this.closeMenu}
                >
                  Artist
                </NavLink>
              </li> */}
              {/* <li className="nav-item active home-tab py-1 mb-2 py-lg-0 mb-lg-0 py-xl-0 mb-xl-0">
                <NavLink
                  to="/categories/general"
                  className="nav-item"
                  onClick={this.closeMenu}
                >
                  Category
                </NavLink>
              </li> */}
              {/* <li className="nav-item active home-tab py-1 mb-2 py-lg-0 mb-lg-0 py-xl-0 mb-xl-0">
                <NavLink
                  to="/journal"
                  className="nav-item"
                  onClick={this.closeMenu}
                >
                  Journal
                </NavLink>
              </li> */}
              <li
                className="nav-item active home-tab py-1 mb-2 py-lg-0 mb-lg-0 py-xl-0 mb-xl-0"
                style={{
                  marginTop: 5,
                }}
              >
                <a href="https://artlife.com" className="nav-item">
                  Back to artlife.com.
                </a>
              </li>
              {/* <li className="nav-item active home-tab py-1 mb-2 py-lg-0 mb-lg-0 py-xl-0 mb-xl-0">
                <NavLink
                  to="/auctions"
                  className="nav-item"
                  onClick={this.closeMenu}
                >
                  Auctions
                </NavLink>
              </li> */}
              {/* <li className="nav-item active home-tab py-1 mb-2 py-lg-0 mb-lg-0 py-xl-0 mb-xl-0">
                <NavLink
                  to="/private-sale"
                  className="nav-item"
                  onClick={this.closeMenu}
                >
                  Private sales
                </NavLink>
              </li> */}
              {/* <li className="nav-item active home-tab py-1 mb-2 py-lg-0 mb-lg-0 py-xl-0 mb-xl-0">
                <NavLink
                  to="/viewing-rooms"
                  className="nav-item"
                  onClick={this.closeMenu}
                >
                  Viewing rooms
                </NavLink>
              </li> */}
              {/* <li className="nav-item active home-tab py-1 mb-2 py-lg-0 mb-lg-0 py-xl-0 mb-xl-0">
                <NavLink
                  to="/discover"
                  className="nav-item"
                  onClick={this.closeMenu}
                >
                  Discover
                </NavLink>
              </li> */}
              <li className="nav-item active home-tab py-1 mb-2 py-lg-0 mb-lg-0 py-xl-0 mb-xl-0">
                <div className="navbar-separator"></div>
              </li>
            </ul>
          </div>

          <div className="col col-lg col-xl nav_search_input_container">
            {!this.state.logged && (
              <li className="nav-item active home-tab special-sign py-1 mb-2 py-lg-0 mb-lg-0 py-xl-0 mb-xl-0">
                <span
                
                  className="nav-item btn_border"
                  onClick={() => this.displayForm("login")}
                >
                  Log in
                </span>
              </li>
            )}
            {!this.state.logged && (
              <li className="nav-item active home-tab special-sign py-1 mb-2 py-lg-0 mb-lg-0 py-xl-0 mb-xl-0">
                <span
            
                  className="nav-item btn_fill"
                  onClick={() => this.displayForm("register")}
                >
                  Sign Up
                </span>
              </li>
            )}
            {this.state.logged && (
              <li className="nav-item active home-tab special-sign py-1 mb-2 py-lg-0 mb-lg-0 py-xl-0 mb-xl-0">
                <NavLink to="/settings" className="nav-item-icon">
                  <i className="fas fa-cog" />
                </NavLink>
              </li>
            )}
            {this.state.logged && (
              <li className="nav-item active home-tab special-sign py-1 mb-2 py-lg-0 mb-lg-0 py-xl-0 mb-xl-0">
                <span
            
                  className="nav-item btn_border"
                  onClick={() => this.signOut()}
                >
                  Sign Out
                </span>
              </li>
            )}
          </div>

          {/* <div className="icons-menu ">
            {this.state.logged !== true && (
              <div className="icon">
                <NavLink to="/search" className="nav-item-icon">
                  <i className="fas fa-search black new_search_margin" />
                </NavLink>
              </div>
            )}
            {this.state.logged !== true && (
              <div className="icon">
                <a
                  className="hover-bag"
                  onClick={() => {
                    this.displayForm("login");
                  }}
                >
                  <i className="fas fa-shopping-bag new_search_margin" />
                </a>
              </div>
            )}
            {this.state.logged && (
              <div className="icon mr-2">
                <NavLink to="/wishlist" className="nav-item-icon">
                  <i className="far fa-heart" />
                </NavLink>
              </div>
            )}
            {this.state.logged && (
              <div className="icon mr-2">
                <NavLink to="/cart" className="nav-item-icon">
                  {this.createBadge()}
                  <i className="fas fa-shopping-bag" />
                </NavLink>
              </div>
            )}
            {this.state.logged && (
              <div className="icon">
                <NavLink to="/settings" className="nav-item-icon">
                  <i className="fas fa-cog" />
                </NavLink>
              </div>
            )}
          </div> */}
        </nav>

        {this.state.formVisible && (
          <LoginForm
            typeForm={this.state.formType}
            close={this.close.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default Nav;
