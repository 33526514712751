import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
require("firebase/firestore");

/**
 * Production Credentials
 */
var prodConfig = {
  apiKey: "AIzaSyBy5sfhO7H_ehrYONhX0FYECA2pyiSIX0w",
  authDomain: "artlife-dev.firebaseapp.com",
  databaseURL: "https://artlife-dev.firebaseio.com",
  projectId: "artlife-dev",
  storageBucket: "artlife-dev.appspot.com",
  messagingSenderId: "1018556892970",
  appId: "1:1018556892970:web:414b39a58fda0ea1a4c053",
  measurementId: "G-JN3CXB6TEZ",
};

var devConfig = {
  apiKey: "AIzaSyCe-MNtvjY6TMxth-7o0JD4NQj4cviHskA",
  authDomain: "artlife-b4a90.firebaseapp.com",
  databaseURL: "https://artlife-b4a90.firebaseio.com",
  projectId: "artlife-b4a90",
  storageBucket: "artlife-b4a90.appspot.com",
  messagingSenderId: "505310270614",
  appId: "1:505310270614:web:43a6f07258a7e8ac619eb7",
  measurementId: "G-31710W0NXF",
};

const config =
  process.env.REACT_APP_INSTA_ENV === "production" ? prodConfig : devConfig;
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const storage = firebase.storage();
const dbFirestore = firebase.firestore();
const auth = firebase.auth();
const timestamp = firebase.firestore.FieldValue.serverTimestamp();
export { storage, dbFirestore, auth, timestamp };
