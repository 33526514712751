//Dependencies
import React, { Component } from "react";
import "./size-guide.css";

class SizeGuidePage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="SizeGuide">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h3 className="title-clothing">Clothing</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-box">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">CLOTHING STANDARD</th>
                      <th scope="col">XXXS</th>
                      <th scope="col">XXS</th>
                      <th scope="col">XS</th>
                      <th scope="col">S</th>
                      <th scope="col">M</th>
                      <th scope="col">L</th>
                      <th scope="col">XL</th>
                      <th scope="col">XXL</th>
                      <th scope="col">XXXL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Italy (IT)</th>
                      <td>36</td>
                      <td>38</td>
                      <td>40</td>
                      <td>42</td>
                      <td>44</td>
                      <td>46</td>
                      <td>48</td>
                      <td>50</td>
                      <td>52</td>
                    </tr>
                    <tr>
                      <th scope="row">France (FR)</th>
                      <td>32</td>
                      <td>34</td>
                      <td>36</td>
                      <td>38</td>
                      <td>40</td>
                      <td>42</td>
                      <td>44</td>
                      <td>46</td>
                      <td>48</td>
                    </tr>
                    <tr>
                      <th scope="row">US (US)</th>
                      <td>0</td>
                      <td>2</td>
                      <td>4</td>
                      <td>6</td>
                      <td>8</td>
                      <td>10</td>
                      <td>12</td>
                      <td>14</td>
                      <td>16</td>
                    </tr>
                    <tr>
                      <th scope="row">UK (UK)</th>
                      <td>4</td>
                      <td>6</td>
                      <td>8</td>
                      <td>10</td>
                      <td>12</td>
                      <td>14</td>
                      <td>16</td>
                      <td>18</td>
                      <td>20</td>
                    </tr>
                    <tr>
                      <th scope="row">Brazil (P-M-GBR)</th>
                      <td>XPP</td>
                      <td>PP</td>
                      <td>P</td>
                      <td>M</td>
                      <td>M</td>
                      <td>G</td>
                      <td>G</td>
                      <td>GG</td>
                      <td>GG</td>
                    </tr>
                    <tr>
                      <th scope="row">Brazil (BR)</th>
                      <td>34</td>
                      <td>36</td>
                      <td>38</td>
                      <td>40</td>
                      <td>42</td>
                      <td>44</td>
                      <td>46</td>
                      <td>48</td>
                      <td>50</td>
                    </tr>
                    <tr>
                      <th scope="row">German (DANISH) DE/DK</th>
                      <td>30</td>
                      <td>32</td>
                      <td>34</td>
                      <td>36</td>
                      <td>38</td>
                      <td>40</td>
                      <td>42</td>
                      <td>44</td>
                      <td>46</td>
                    </tr>
                    <tr>
                      <th scope="row">Standard Numeric (NUM)</th>
                      <td>000</td>
                      <td>00</td>
                      <td>0</td>
                      <td>1</td>
                      <td>2</td>
                      <td>3</td>
                      <td>4</td>
                      <td>5</td>
                      <td>6</td>
                    </tr>
                    <tr>
                      <th scope="row">Roman Numeric (NUM)</th>
                      <td>000</td>
                      <td>00</td>
                      <td>0</td>
                      <td>I</td>
                      <td>II</td>
                      <td>III</td>
                      <td>IV</td>
                      <td>V</td>
                      <td>VI</td>
                    </tr>
                    <tr>
                      <th scope="row">Japan (JP)</th>
                      <td>3</td>
                      <td>5</td>
                      <td>7</td>
                      <td>9</td>
                      <td>11</td>
                      <td>13</td>
                      <td>15</td>
                      <td>17</td>
                      <td>19</td>
                    </tr>
                    <tr>
                      <th scope="row">Korea (KR)</th>
                      <td></td>
                      <td>33</td>
                      <td>44</td>
                      <td>55</td>
                      <td>66</td>
                      <td>77</td>
                      <td>88</td>
                      <td>99</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row">China (CH)</th>
                      <td>145/73A</td>
                      <td>150/76A</td>
                      <td>155/80A</td>
                      <td>160/84A</td>
                      <td>165/88A</td>
                      <td>170/92A</td>
                      <td>175/96A</td>
                      <td>180/100A</td>
                      <td>185/104A</td>
                    </tr>
                    <tr>
                      <th scope="row">Australian (AUS)</th>
                      <td>4</td>
                      <td>6</td>
                      <td>8</td>
                      <td>10</td>
                      <td>12</td>
                      <td>14</td>
                      <td>16</td>
                      <td>18</td>
                      <td>20</td>
                    </tr>
                    <tr>
                      <th scope="row">Russia (RU)</th>
                      <td>38</td>
                      <td>40</td>
                      <td>42</td>
                      <td>44</td>
                      <td>46</td>
                      <td>48</td>
                      <td>50</td>
                      <td>52</td>
                      <td>54</td>
                    </tr>
                  </tbody>
                </table>
                <br />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <h3 className="title-shoes">Shoes</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-box">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Italy (IT)</th>
                      <th scope="col">34</th>
                      <th scope="col">34.5</th>
                      <th scope="col">35</th>
                      <th scope="col">35.5</th>
                      <th scope="col">36</th>
                      <th scope="col">36.5</th>
                      <th scope="col">37</th>
                      <th scope="col">37.5</th>
                      <th scope="col">38</th>
                      <th scope="col">38.5</th>
                      <th scope="col">39</th>
                      <th scope="col">39.5</th>
                      <th scope="col">40</th>
                      <th scope="col">40.5</th>
                      <th scope="col">41</th>
                      <th scope="col">41.5</th>
                      <th scope="col">42</th>
                      <th scope="col">42.5</th>
                      <th scope="col">43</th>
                      <th scope="col">43.5</th>
                      <th scope="col">44</th>
                      <th scope="col">44.5</th>
                      <th scope="col">45</th>
                      <th scope="col">45.5</th>
                      <th scope="col">46</th>
                      <th scope="col">46.5</th>
                      <th scope="col">47</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">US (US)</th>
                      <td>4</td>
                      <td>4.5</td>
                      <td>5</td>
                      <td>5.5</td>
                      <td>6</td>
                      <td>6.5</td>
                      <td>7</td>
                      <td>7.5</td>
                      <td>8</td>
                      <td>8.5</td>
                      <td>9</td>
                      <td>9.5</td>
                      <td>10</td>
                      <td>10.5</td>
                      <td>11</td>
                      <td>11.5</td>
                      <td>12</td>
                      <td>12.5</td>
                      <td>13</td>
                      <td>13.5</td>
                      <td>14</td>
                      <td>14.5</td>
                      <td>15</td>
                      <td>15.5</td>
                      <td>16</td>
                      <td>16.5</td>
                      <td>17</td>
                    </tr>
                    <tr>
                      <th scope="row">UK (UK)</th>
                      <td>1</td>
                      <td>1.5</td>
                      <td>2</td>
                      <td>2.5</td>
                      <td>3</td>
                      <td>3.5</td>
                      <td>4</td>
                      <td>4.5</td>
                      <td>5</td>
                      <td>5.5</td>
                      <td>6</td>
                      <td>6.5</td>
                      <td>7</td>
                      <td>7.5</td>
                      <td>8</td>
                      <td>8.5</td>
                      <td>9</td>
                      <td>9.5</td>
                      <td>10</td>
                      <td>10.5</td>
                      <td>11</td>
                      <td>11.5</td>
                      <td>12</td>
                      <td>12.5</td>
                      <td>13</td>
                      <td>13.5</td>
                      <td>14</td>
                    </tr>
                    <tr>
                      <th scope="row">France (FR)</th>
                      <td>35</td>
                      <td>35.5</td>
                      <td>36</td>
                      <td>36.5</td>
                      <td>37</td>
                      <td>37.5</td>
                      <td>38</td>
                      <td>38.5</td>
                      <td>39</td>
                      <td>39.5</td>
                      <td>40</td>
                      <td>40.5</td>
                      <td>41</td>
                      <td>41.5</td>
                      <td>42</td>
                      <td>42.5</td>
                      <td>43</td>
                      <td>43.5</td>
                      <td>44</td>
                      <td>44.5</td>
                      <td>45</td>
                      <td>45.5</td>
                      <td>46</td>
                      <td>46.5</td>
                      <td>47</td>
                      <td>47.5</td>
                      <td>48</td>
                    </tr>
                    <tr>
                      <th scope="row">Brazil (BR)</th>
                      <td>33</td>
                      <td>33.5</td>
                      <td>34</td>
                      <td>34.5</td>
                      <td>35</td>
                      <td>35.5</td>
                      <td>36</td>
                      <td>36.5</td>
                      <td>37</td>
                      <td>37.5</td>
                      <td>38</td>
                      <td>38.5</td>
                      <td>39</td>
                      <td>39.5</td>
                      <td>40</td>
                      <td>40.5</td>
                      <td>41</td>
                      <td>41.5</td>
                      <td>42</td>
                      <td>42.5</td>
                      <td>43</td>
                      <td>43.5</td>
                      <td>44</td>
                      <td>44.5</td>
                      <td>45</td>
                      <td>45.5</td>
                      <td>46</td>
                    </tr>
                    <tr>
                      <th scope="row">Japan (JP)</th>
                      <td>21</td>
                      <td>21.5</td>
                      <td>22</td>
                      <td>22.5</td>
                      <td>23</td>
                      <td>23.5</td>
                      <td>24</td>
                      <td>24.5</td>
                      <td>25</td>
                      <td>25.5</td>
                      <td>26</td>
                      <td>26.5</td>
                      <td>27</td>
                      <td>27.5</td>
                      <td>28</td>
                      <td>28.5</td>
                      <td>29</td>
                      <td>29.5</td>
                      <td>30</td>
                      <td>30.5</td>
                      <td>31</td>
                      <td>31.5</td>
                      <td>32</td>
                      <td>32.5</td>
                      <td>33</td>
                      <td>33.5</td>
                      <td>34</td>
                    </tr>
                    <tr>
                      <th scope="row">Korea (KR)</th>
                      <td>210</td>
                      <td>215</td>
                      <td>220</td>
                      <td>225</td>
                      <td>230</td>
                      <td>235</td>
                      <td>240</td>
                      <td>245</td>
                      <td>250</td>
                      <td>255</td>
                      <td>260</td>
                      <td>265</td>
                      <td>270</td>
                      <td>275</td>
                      <td>280</td>
                      <td>285</td>
                      <td>290</td>
                      <td>295</td>
                      <td>300</td>
                      <td>305</td>
                      <td>310</td>
                      <td>315</td>
                      <td>320</td>
                      <td>325</td>
                      <td>330</td>
                      <td>335</td>
                      <td>340</td>
                    </tr>
                    <tr>
                      <th scope="row">China (CH)</th>
                      <td>34</td>
                      <td>34.5</td>
                      <td>35</td>
                      <td>35.5</td>
                      <td>36</td>
                      <td>36.5</td>
                      <td>37</td>
                      <td>37.5</td>
                      <td>38</td>
                      <td>38.5</td>
                      <td>39</td>
                      <td>39.5</td>
                      <td>40</td>
                      <td>40.5</td>
                      <td>41</td>
                      <td>41.5</td>
                      <td>42</td>
                      <td>42.5</td>
                      <td>43</td>
                      <td>43.5</td>
                      <td>44</td>
                      <td>44.5</td>
                      <td>45</td>
                      <td>45.5</td>
                      <td>46</td>
                      <td>46.5</td>
                      <td>47</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <button
                className="btn btn-secondary btn-size-back "
                onClick={() => {
                  document.querySelector("#sizeModal .close").click();
                }}
              >
                GO BACK
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SizeGuidePage;
