//Dependencies
import React, { Component } from "react";
import { auth, dbFirestore, timestamp, storage } from "../../@firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";
import logo from "../../assets/logo.gif";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import loadImage from "blueimp-load-image";
import * as firebase from "firebase/app";
import Success from "../../@components/success";
import SuccessCreateAccount from "../../@components/succress-create-account";
import CreateAccountNotification from "../../@components/create-account-notification";
require("firebase/auth");
const reg = /(.+)@(.+){2,}\.(.+){2,}/;
const uuidv4 = require("uuid/v4");

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "password",
      error: "",
      success: "",
      disabled: false,
      logged: false,
      title_alert: "",
      title_message: "",
      image: "",
      newImage: false,
      user: {},
      email: "",
      pass: "",
      name: "",
      phone: "",
      active: true,
    };
    this.changeState = this.changeState.bind(this);
    this.handleSubmitLog = this.handleSubmitLog.bind(this);
    this.handleSubmitRegister = this.handleSubmitRegister.bind(this);
    this.handleSubmitSend = this.handleSubmitSend.bind(this);
    this.dispose = this.dispose.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
    this.showHide = this.showHide.bind(this);
    this.disposeOut = this.disposeOut.bind(this);
    this.notify = this.notify.bind(this);
    this.handleNewImage = this.handleNewImage.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.setPropByKey = this.setPropByKey.bind(this);
  }
  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === "input" ? "password" : "input",
    });
  }

  notify = () => {
    if (!toast.isActive(this.toastId)) {
      this.toastId = toast.info("We're signing you in to your account", {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: "notify",
        bodyClassName: "notify-body",
        position: "bottom-center",
        toastId: "login",
      });
    }
  };

  creationNotify = () => {
    if (!toast.isActive(this.toastId)) {
      this.toastId = toast.info(<CreateAccountNotification />, {
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: "notify",
        bodyClassName: "notify-body",
        position: "bottom-center",
        toastId: "login",
      });
    }
  };

  updateCreate = () => {
    toast.update(this.toastId, {
      render: <SuccessCreateAccount />,
      type: toast.TYPE.INFO,
      autoClose: 1500,
      hideProgressBar: true,
      className: "notify",
      bodyClassName: "notify-body",
      position: "bottom-center",
      toastId: "login",
    });
  };

  update = () => {
    toast.update(this.toastId, {
      render: <Success />,
      type: toast.TYPE.INFO,
      autoClose: 1000,
      hideProgressBar: true,
      className: "notify",
      bodyClassName: "notify-body",
      position: "bottom-center",
      toastId: "login",
    });
  };

  componentDidMount() {
    const typeVisible = this.props.typeForm;
    this.setState({
      formVisible: typeVisible,
    });
    if (this.state.active) {
      document.querySelector("#root").classList.add("blockScroll");
    } else {
      document.querySelector("#root").classList.remove("blockScroll");
    }
    return this.state.active;
  }

  setPropByKey(value, key) {
    this.setState({ [key]: value });
  }

  componentWillUnmount() {
    this.setState({
      active: false,
    });
    document.querySelector("#root").classList.remove("blockScroll");
  }

  async handleSubmitLog(e) {
    e.preventDefault();
    document.querySelector(`.login-form .error-login`).classList.add("hidden");
    /*const email = document.querySelector(`.login-form input[name="email"]`).value;
    const pass = document.querySelector(`.login-form input[name="pass"]`).value;*/

    const email = this.state.email;
    const pass = this.state.pass;

    try {
      await auth.signInWithEmailAndPassword(email, pass);
      this.notify();
      setTimeout(
        function () {
          this.update();
        }.bind(this),
        3000
      );
      setTimeout(
        function () {
          this.props.close();
        }.bind(this),
        4500
      );
    } catch (error) {
      document
        .querySelector(`.login-form .error-login`)
        .classList.remove("hidden");
      console.log(error);
    }
  }

  async handleSubmitRegister(e) {
    e.preventDefault();
    /*const email = document.querySelector(`.register-form input[name="email"]`).value;
    const pass = document.querySelector(`.register-form input[name="pass"]`).value;
    const name = document.querySelector(`.register-form input[name="name"]`).value;
    const username = document.querySelector(`.register-form input[name="username"]`).value;*/
    const email = this.state.email;
    const pass = this.state.pass;
    const name = this.state.name;
    const phone = this.state.phone;

    try {
      let authUser = await auth.createUserWithEmailAndPassword(email, pass);
      this.creationNotify();

      await dbFirestore.collection("users").doc(authUser.user.uid).set({
        id: authUser.user.uid,
        email: email,
        name: name,
        phone: phone,
        typeOf: "user",
        cart: [],
        wishlist: [],
        wishlist_lots: [],
        orders: [],
        customer_id: "",
        default_card: "",
        address: [],
        mapJson: "",
        createdAt: timestamp,
        // photo: "",
        referrals: [],
      });
      // let photo = await this.uploadImage(this.state.image, "-profile.jpg");
      // await dbFirestore
      //   .collection("users")
      //   .doc(authUser.user.uid)
      //   .update({ photo: photo });
      await dbFirestore
        .collection("users_identifier")
        .doc(authUser.user.uid)
        .set({ id: authUser.user.uid });
      if (authUser) {
        this.updateCreate();
        this.props.close();
      }
    } catch (error) {
      let errorMessage = error.message;
      this.setState({ error: errorMessage });
    }
  }

  async handleSubmitSend(e) {
    e.preventDefault();
    const email = this.state.email;
    try {
      const SuccessMessage =
        "The email was sent successfully to your email address";
      await auth.sendPasswordResetEmail(email);
      this.setState({
        success: SuccessMessage,
        disabled: true,
        error: "",
      });
    } catch (error) {
      console.log(error);
      if (error.code === "auth/user-not-found") {
        error.message = "The email address you have entered is not registered";
      } else if (error.code === "auth/too-many-requests") {
        error.message = "The email is already sent to your email address";
      }
      this.setState({
        error: error.message,
        success: "",
        disabled: false,
      });
    }
  }

  handleBlurRegister(e) {
    const type = e.target.getAttribute("name");
    if (type === "pass") {
      const pass = document.querySelector(`.register-form input[name="pass"]`)
        .value;
      if (pass.length >= 6) {
        document
          .querySelector(`.register-form input[name="pass"]`)
          .classList.remove("error-input");
        document
          .querySelector(`.register-form .error-pass`)
          .classList.add("hidden");
      } else {
        document
          .querySelector(`.register-form input[name="pass"]`)
          .classList.add("error-input");
        document
          .querySelector(`.register-form .error-pass`)
          .classList.remove("hidden");
      }
    } else if (type === "email-reg") {
      const email = document.querySelector(
        `.register-form input[name="email-reg"]`
      ).value;
      if (reg.test(email) === true) {
        document
          .querySelector(`.register-form input[name="email-reg"]`)
          .classList.remove("error-input");
        document
          .querySelector(`.register-form .error-email`)
          .classList.add("hidden");
      } else {
        document
          .querySelector(`.register-form input[name="email-reg"]`)
          .classList.add("error-input");
        document
          .querySelector(`.register-form .error-email`)
          .classList.remove("hidden");
      }
    } else if (type === "phone") {
      const phone = document.querySelector(`.register-form input[name="phone"]`)
        .value;
      if (phone.length > 0) {
        document
          .querySelector(`.register-form input[name="pass"]`)
          .classList.remove("error-input");
        document
          .querySelector(`.register-form .error-phone`)
          .classList.add("hidden");
      } else {
        document
          .querySelector(`.register-form input[name="pass"]`)
          .classList.add("error-input");
        document
          .querySelector(`.register-form .error-phone`)
          .classList.remove("hidden");
      }
    } else {
      const name = document.querySelector(`.register-form input[name="name"]`)
        .value;
      if (name.length > 4) {
        document
          .querySelector(`.register-form input[name="name"]`)
          .classList.remove("error-input");
        document
          .querySelector(`.register-form .error-name`)
          .classList.add("hidden");
      } else {
        document
          .querySelector(`.register-form input[name="name"]`)
          .classList.add("error-input");
        document
          .querySelector(`.register-form .error-name`)
          .classList.remove("hidden");
      }
    }
  }

  handleBlurLog(e) {
    const type = e.target.getAttribute("name");
    if (type === "pass") {
      const pass = document.querySelector(`.login-form input[name="pass"]`)
        .value;
      if (pass.length >= 6) {
        document
          .querySelector(`.login-form input[name="pass"]`)
          .classList.remove("error-input");
        document
          .querySelector(`.login-form .error-pass`)
          .classList.add("hidden");
      } else {
        document
          .querySelector(`.login-form input[name="pass"]`)
          .classList.add("error-input");
        document
          .querySelector(`.login-form .error-pass`)
          .classList.remove("hidden");
      }
    } else if (type === "email") {
      const email = document.querySelector(`.login-form input[name="email"]`)
        .value;
      if (reg.test(email) === true) {
        document
          .querySelector(`.login-form input[name="email"]`)
          .classList.remove("error-input");
        document
          .querySelector(`.login-form .error-email`)
          .classList.add("hidden");
      } else {
        document
          .querySelector(`.login-form input[name="email"]`)
          .classList.add("error-input");
        document
          .querySelector(`.login-form .error-email`)
          .classList.remove("hidden");
      }
    }
  }

  handleBlurSend(e) {
    const email = document.querySelector(`.send-form input[name="email"]`)
      .value;
    if (reg.test(email) === true) {
      document
        .querySelector(`.send-form input[name="email"]`)
        .classList.remove("error-input");
      document.querySelector(`.send-form .error-email`).classList.add("hidden");
    } else {
      document
        .querySelector(`.send-form input[name="email"]`)
        .classList.add("error-input");
      document
        .querySelector(`.send-form .error-email`)
        .classList.remove("hidden");
    }
  }

  changeState(value) {
    this.setState({
      formVisible: value,
      email: "",
      pass: "",
      name: "",
    });
  }

  dispose() {
    this.props.close();
  }

  disposeOut(e) {
    if (document.querySelector(".login-bg").contains(e.target)) {
      // Clicked in box
    } else {
      // Clicked outside the box
      this.dispose();
    }
  }

  responseFacebook = async (response) => {
    var provider = firebase.auth.FacebookAuthProvider.credential(
      response.accessToken
    );
    var authUser = await firebase
      .auth()
      .signInAndRetrieveDataWithCredential(provider);
    try {
      var doc = await dbFirestore
        .collection("users")
        .doc(authUser.user.uid)
        .get();
      if (!doc.exists) {
        dbFirestore
          .collection("users")
          .doc(authUser.user.uid)
          .set({
            id: authUser.user.uid,
            email: authUser.user.email,
            name: authUser.user.displayName,
            typeOf: "user",
            cart: [],
            wishlist: [],
            wishlist_lots: [],
            orders: [],
            customer_id: "",
            default_card: "",
            address: [],
            mapJson: "",
            createdAt: timestamp,
          })
          .then((docRef) => {
            this.setState({ visible: false });
            //Go to close login modal
            this.dispose();
          })
          .catch((error) => {
            this.setState({
              visible: false,
            });
            console.error("Error adding document: ", error);
          });
      } else {
        this.setState({ visible: false });
        this.dispose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  handleNewImage(e) {
    const loadImageOptions = { canvas: true };
    const file = e.target.files[0];
    loadImage.parseMetaData(file, (data) => {
      if (data.exif && data.exif.get("Orientation")) {
        loadImageOptions.orientation = data.exif.get("Orientation");
      }
      loadImage(
        file,
        (canvas) => {
          this.setState({
            image: canvas.toDataURL(file.type),
            newImage: true,
          });
        },
        loadImageOptions
      );
    });
  }

  uploadImage(file, type) {
    const name = `${uuidv4()}${type}`;
    return storage
      .ref("images")
      .child(name)
      .putString(file, "data_url")
      .then((snapshot) => snapshot.ref.getDownloadURL())
      .catch((error) => {
        throw new Error(error);
      });
  }

  getError() {
    let classes = "error-text error-email ";
    classes += this.state.error.length !== 0 ? "" : "hidden";
    return classes;
  }

  getSuccess() {
    let classes = "success-text ";
    classes += this.state.success.length !== 0 ? "" : "hidden";
    return classes;
  }

  render() {
    // const imageUrl = this.state.image;
    // const FACEBOOK_APP_ID = Constants.facebookKey();
    if (
      this.state !== null &&
      this.state !== undefined &&
      this.state.formVisible === "login"
    ) {
      return (
        // Log in
        <div
          className="forms-box"
          onClick={(e) => {
            this.disposeOut(e);
          }}
        >
          <div className="box-form">
            <div className="container h-100">
              <div className="row h-100 centered">
                <div className="col-12 max-w-440">
                  <div className="login-bg">
                    <p className="close_x" onClick={this.dispose}>
                      <i className="fas fa-times" />
                    </p>
                    <img className="logo-modal" src={logo} alt="ArtLife" />
                    <p className="slogan my-3">The art world online</p>
                    <form
                      className="login-form"
                      onSubmit={this.handleSubmitLog}
                    >
                      <div className="col-12">
                        <input
                          className="form-control mb-3 new-input"
                          name="email"
                          type="email"
                          placeholder="Enter your email address"
                          onBlur={this.handleBlurLog}
                          value={this.state.email}
                          onChange={(e) => {
                            this.setPropByKey(e.target.value, "email");
                          }}
                          required
                        />
                        <p className="hidden error-text error-email">
                          Please enter a valid email.
                        </p>
                      </div>
                      <div className="col-12">
                        <input
                          className="form-control new-input"
                          name="pass"
                          type={this.state.type}
                          placeholder="Enter your password"
                          value={this.state.pass}
                          onBlur={this.handleBlurLog}
                          onChange={(e) => {
                            this.setPropByKey(e.target.value, "pass");
                          }}
                          required
                        />
                        <div className="password-eye" onClick={this.showHide}>
                          {this.state.type === "input" ? (
                            <i className="fas fa-eye-slash" />
                          ) : (
                            <i className="fas fa-eye" />
                          )}
                        </div>
                        <p className="hidden error-text error-pass">
                          Your password has a minimum of 6 characters.
                        </p>
                      </div>
                      <div className="col-12">
                        <p className="forgot">
                          <p
                            style={{ cursor: "pointer" }}
                            className="underlined-b my-3"
                            onClick={() => this.changeState("forget")}
                          >
                            Forgot password
                          </p>
                        </p>
                        <p className="hidden error-text error-login">
                          Email or password are incorrect.
                        </p>
                        <button
                          className="btn btn-secondary btn-menu btn-size"
                          type="submit"
                        >
                          Log In
                        </button>
                        <p className="other-options">
                          {/* Log in using{" "}
                          <a className="underlined-b">
                            <FacebookLogin
                              appId={FACEBOOK_APP_ID}
                              autoLoad={false}
                              callback={this.responseFacebook}
                              render={renderProps => (
                                <span onClick={renderProps.onClick}>
                                  Facebook
                                </span>
                              )}
                            /> */}
                          {/* </a>{" "} */}
                          Don’t have an account?{" "}
                          <span
                            style={{ cursor: "pointer" }}
                            className="underlined-b"
                            onClick={() => this.changeState("register")}
                          >
                            Sign up.
                          </span>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (
      this.state !== null &&
      this.state !== undefined &&
      this.state.formVisible === "register"
    ) {
      return (
        // Register
        <div className="forms-box">
          <div className="box-form">
            <div className="container h-100">
              <div className="row h-100 centered">
                <div className="col-12 max-w-440">
                  <div className="login-bg">
                    <p className="close_x" onClick={this.dispose}>
                      <i className="fas fa-times" />
                    </p>
                    <img className="logo-modal" src={logo} alt="ArtLife" />
                    <p className="slogan my-3">The art world online</p>
                    <form
                      className="register-form"
                      onSubmit={this.handleSubmitRegister}
                    >
                      <div className="row">
                        <div className="col-12">
                          <div className="grid">
                            {/* <figure className="effect-zoe">
                              {this.state.image !== "" && (
                                <img
                                  src={imageUrl}
                                  alt="Upload"
                                  className="image"
                                />
                              )}
                              {!this.state.image !== "" && (
                                <img
                                  src="/unknown-person.png"
                                  alt="Upload"
                                  className="image"
                                />
                              )}
                              <input
                                name="newImage"
                                type="file"
                                multiple
                                accept="image/*"
                                className="input-new-image"
                                onChange={this.handleNewImage}
                                required
                              />
                              <figcaption>
                                <p>Upload Image</p>
                                <span>
                                  <i className="fas fa-camera" />
                                </span>
                              </figcaption>
                            </figure> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <input
                          className="form-control mb-3 new-input"
                          type="email"
                          name="email-reg"
                          placeholder="Enter your email address"
                          value={this.state.email}
                          onBlur={this.handleBlurRegister}
                          onChange={(e) => {
                            this.setPropByKey(e.target.value, "email");
                          }}
                          required
                        />
                        <p className="hidden error-text error-email">
                          Please enter a valid email.
                        </p>
                        <p className="error-text error-email">
                          {this.state.error}
                        </p>
                      </div>
                      <div className="col-12">
                        <input
                          className="form-control new-input"
                          type={this.state.type}
                          name="pass"
                          placeholder="Enter your password"
                          value={this.state.pass}
                          onBlur={this.handleBlurRegister}
                          onChange={(e) => {
                            this.setPropByKey(e.target.value, "pass");
                          }}
                          required
                        />
                        <div className="password-eye" onClick={this.showHide}>
                          {this.state.type === "input" ? (
                            <i className="fas fa-eye-slash" />
                          ) : (
                            <i className="fas fa-eye" />
                          )}
                        </div>
                        <p className="hidden error-text error-pass">
                          Password required.
                        </p>
                      </div>
                      <div className="col-12">
                        <input
                          className="form-control new-input"
                          type="text"
                          name="name"
                          placeholder="Enter your full name"
                          value={this.state.name}
                          onBlur={this.handleBlurRegister}
                          onChange={(e) => {
                            this.setPropByKey(e.target.value, "name");
                          }}
                          required
                        />
                        <p className="hidden error-text error-name">
                          Full name is required.
                        </p>
                      </div>

                      <div className="col-12">
                        <input
                          className="form-control new-input"
                          type="text"
                          name="phone"
                          placeholder="Enter your phone"
                          value={this.state.phone}
                          onBlur={this.handleBlurRegister}
                          onChange={(e) => {
                            this.setPropByKey(e.target.value, "phone");
                          }}
                        />
                        <p className="hidden error-text error-phone">
                          Phone is required.
                        </p>
                      </div>

                      <div className="col-12">
                        <button
                          className="btn btn-secondary btn-menu btn-size"
                          type="submit"
                        >
                          Sign up
                        </button>
                        <p className="other-options">
                          {/* Sign up using{" "} */}
                          <a className="underlined-b">
                            {/* {/* <FacebookLogin
                              appId={FACEBOOK_APP_ID}
                              autoLoad={false}
                              callback={this.responseFacebook}
                              render={renderProps => (
                                <span onClick={renderProps.onClick}>
                                  Facebook
                                </span>
                              )}
                            /> */}
                          </a>
                          Already have an account?{" "}
                          <span
                            style={{ cursor: "pointer" }}
                            className="underlined-b"
                            onClick={() => this.changeState("login")}
                          >
                            Log in.
                          </span>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (
      this.state !== null &&
      this.state !== undefined &&
      this.state.formVisible === "forget"
    ) {
      return (
        // Forgot Password
        <div className="forms-box">
          <div className="box-form">
            <div className="container h-100">
              <div className="row h-100 centered">
                <div className="col-12 max-w-440">
                  <div className="login-bg">
                    <p className="close_x" onClick={this.dispose}>
                      <i className="fas fa-times" />
                    </p>
                    <img className="logo-modal" src={logo} alt="ArtLife" />
                    <form
                      className="send-form"
                      onSubmit={this.handleSubmitSend}
                    >
                      <div className="col-12">
                        <input
                          className="form-control mb-3 new-input"
                          type="email"
                          name="email"
                          placeholder="Enter your email address"
                          value={this.state.email}
                          onChange={(e) => {
                            this.setPropByKey(e.target.value, "email");
                          }}
                          onBlur={this.handleBlurSend}
                          required
                        />
                        <p className="hidden error-text error-email">
                          Please enter a valid email.
                        </p>

                        <p className={this.getError()}>{this.state.error}</p>

                        <p className={this.getSuccess()}>
                          {this.state.success}
                        </p>
                      </div>
                      <div className="col-12">
                        <button
                          className="btn btn-secondary btn-menu btn-size"
                          id="resetDisable"
                          disabled={this.state.disabled}
                          type="submit"
                        >
                          Send Reset Instructions
                        </button>
                        <p className="other-options">
                          Don’t need to reset?
                          <p
                            style={{ cursor: "pointer" }}
                            className="underlined-b"
                            onClick={() => this.changeState("login")}
                          >
                            Log in
                          </p>{" "}
                          or{" "}
                          <p
                            style={{ cursor: "pointer" }}
                            className="underlined-b"
                            onClick={() => this.changeState("register")}
                          >
                            Sign up.
                          </p>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default LoginForm;
