//Dependencies
import React, { Component } from "react";
import { dbFirestore, auth } from "../../@firebase";
import { Link, Redirect } from "react-router-dom";
import "./address-book.css";
import Loader from "react-loader-spinner";
import { metasTags } from "../../#constants";
class AddressBookPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      user: {},
      logged: false,
      infoUser: {},
      redirect: false,
      visibleLoader: true,
      visibleLoaderAll: false,
      checkout: false,
    };
    this.dismarkAll = this.dismarkAll.bind(this);
    this.init = this.init.bind(this);
    this.setProps = this.setProps.bind(this);
    this._isMounted = false;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    metasTags.setMetaTags();
    this.setProps();
    auth.onAuthStateChanged((user) => {
      if (user) {
        var userLogged = auth.currentUser;
        this._isMounted &&
          this.setState({ user: userLogged, logged: true, redirect: false });
        this.init();
      } else {
        this._isMounted &&
          this.setState({
            user: {},
            logged: false,
            infoUser: {},
            redirect: true,
            visibleLoader: false,
          });
      }
    });
  }

  setProps() {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.data.checkout) {
        this._isMounted && this.setState({ checkout: true });
      }
    } else {
      //sin props se debe mandar al home
      this._isMounted && this.setState({ redirect: true });
    }
  }

  async init() {
    try {
      let snapshot = await dbFirestore
        .collection("users")
        .doc(auth.currentUser.uid)
        .get();
      let infoUser = snapshot.data();
      let address = infoUser.address;
      this._isMounted &&
        this.setState({ data: address, infoUser: infoUser }, () => {
          this._isMounted && this.setState({ visibleLoader: false });
        });
    } catch (error) {
      console.error(error);
    }
  }

  componentWillReceiveProps(props) {
    //Recargando la data de la vista nuevamente
    this.componentDidMount();
  }

  async selectAddressDefault(value, item, index) {
    let address = this.state.data;
    let tam = address.length;
    let i;
    this._isMounted && this.setState({ visibleLoaderAll: true });
    for (i = 0; i < tam; i++) {
      if (address[i].default) {
        address[i].default = false;
        break;
      }
    }

    for (i = 0; i < tam; i++) {
      if (address[i].address === item.address && i === index) {
        address[i].default = true;
        break;
      }
    }

    let cart = this.state.infoUser.cart;
    let tamCart = cart.length;
    for (i = 0; i < tamCart; i++) {
      cart[i].shipping = 0;
      cart[i].shippingID = "";
    }

    try {
      await dbFirestore
        .collection("users")
        .doc(this.state.user.uid)
        .update({ address: address, cart: cart });
      this.dismarkAll(index);
      this._isMounted && this.setState({ visibleLoaderAll: false });
    } catch (error) {
      console.error("Error ", error);
      this._isMounted && this.setState({ visibleLoaderAll: false });
    }
    /*if (!value) {
      console.log("entre en el if");
      let ind;
      for (i = 0; i < tam; i++) {
        if (address[i].default) {
          address[i].default = false;
          ind = i;
          break;
        }
      }
      this._isMounted && this.setState({ data: address }, () => {
        address[ind].default = true;
        this._isMounted && this.setState({ data: address, visibleLoaderAll: false });
      });
    } 
    else {
      console.log("entre en el else");
      for (i = 0; i < tam; i++) {
        if (address[i].default) {
          address[i].default = false;
          break;
        }
      }

      if (value) {
        for (i = 0; i < tam; i++) {
          if (address[i].address === item.address) {
            address[i].default = true;
            break;
          }
        }
      }

      let cart = this.state.infoUser.cart;
      let tamCart = cart.length;
      for (i = 0; i < tamCart; i++) {
        cart[i].shipping = 0;
        cart[i].shippingID = "";
      }

      try {
        await dbFirestore.collection("users").doc(this.state.user.uid).update({ address: address, cart: cart });
        this.dismarkAll(index);
        this._isMounted && this.setState({ visibleLoaderAll: false });
      } 
      catch (error) {
        console.error("Error ", error);
        this._isMounted && this.setState({ visibleLoaderAll: false });
      }
    }
    */
  }

  async deleteAddress(item, index) {
    this._isMounted && this.setState({ visibleLoaderAll: true });
    let address = this.state.data;
    let newaddress = address.filter((value, ind) => ind !== Number(index));

    if (newaddress.length === 0) {
      let cart = this.state.infoUser.cart;
      let tam = cart.length;
      for (let i = 0; i < tam; i++) {
        cart[i].shipping = 0;
        cart[i].shippingID = "";
      }
      try {
        await dbFirestore
          .collection("users")
          .doc(this.state.user.uid)
          .update({ address: newaddress, cart: cart });
        this._isMounted &&
          this.setState({ data: newaddress, visibleLoaderAll: false });
      } catch (error) {
        this._isMounted && this.setState({ visibleLoaderAll: false });
        console.error("Error ", error);
      }
    } else {
      try {
        await dbFirestore
          .collection("users")
          .doc(this.state.user.uid)
          .update({ address: newaddress });
        this._isMounted &&
          this.setState({ data: newaddress, visibleLoaderAll: false });
      } catch (error) {
        this._isMounted && this.setState({ visibleLoaderAll: false });
        console.error("Error ", error);
      }
    }
  }

  dismarkAll(index) {
    document.querySelectorAll(".switch input").forEach((value, i) => {
      if (index !== i) {
        value.checked = false;
      }
    });
  }

  renderItems() {
    if (this.state !== null && this.state !== undefined) {
      let items = [];
      this.state.data.forEach((element, index) => {
        items.push(
          <div className="col-12 new-col-pd" key={"addbook-" + index}>
            <div className="card cards-addbook">
              <div className="card-body addbook-card-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-8 col-md-6 first-col-addbook">
                      <p className="name-addbook">{element.name}</p>
                      <p className="add-addbook">{element.address}</p>
                      {element.default && (
                        <p className="prefered-addbook">
                          My preferred shipping address
                        </p>
                      )}
                    </div>
                    <div className="col-4 col-md-6 second-col-addbook">
                      <div className="top-addbook">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={element.default ? true : false}
                            onChange={(value) => {
                              this.selectAddressDefault(value, element, index);
                            }}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                      <div className="bottom-addbook">
                        <p
                          className="trash-addbook"
                          onClick={() => {
                            this.deleteAddress(element, index);
                          }}
                        >
                          <i className="fas fa-trash" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });

      return items;
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div className="Addbook">
        {this.state.redirect && <Redirect push to={"/"} />}
        {this.state.visibleLoader && (
          <div className="loader-book">
            <Loader type="Oval" color="#000" height="50" width="50" />
          </div>
        )}
        {this.state.visibleLoaderAll && (
          <div className="loader-area">
            <div className="loader-box">
              <Loader type="Oval" color="#000" height="50" width="50" />
            </div>
          </div>
        )}
        <div className="container-fluid bg-intro-addbook">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="text-center title-addbook my-3 closit_text">
                  Addres Book
                </h1>
              </div>

              <div className="col-12 col-md-6">
                {!this.state.checkout && (
                  <Link to="/settings">
                    <button className="btn btn-secondary btn-new-addbook">
                      GO BACK
                    </button>
                  </Link>
                )}
                {this.state.checkout && (
                  <Link to="/checkout">
                    <button className="btn btn-secondary btn-new-addbook">
                      GO BACK
                    </button>
                  </Link>
                )}
              </div>
              <div className="col-12 col-md-6">
                <Link
                  to={{
                    pathname: "/pick-place",
                    state: { data: { checkout: this.state.checkout } },
                  }}
                >
                  <button className="btn btn-secondary btn-new-addbook">
                    Add New Addres
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid no-pd">
          <div className="container">
            <div className="row">{this.renderItems()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddressBookPage;
