import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./slider-nav.css";

class SliderNav extends Component {
  render() {
    return (
      <div className="SliderNav">
        <ul className="nav nav-tabs hide_nav" id="myTab" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              id="description-tab"
              data-toggle="tab"
              href="#description"
              role="tab"
              aria-controls="description"
              aria-selected="true"
            >
              Description
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="size-tab"
              data-toggle="tab"
              href="#size"
              role="tab"
              aria-controls="size"
              aria-selected="false"
            >
              Size & Fit
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="composition-tab"
              data-toggle="tab"
              href="#composition"
              role="tab"
              aria-controls="composition"
              aria-selected="false"
            >
              Composition & Care
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="shipping-tab"
              data-toggle="tab"
              href="#shipping"
              role="tab"
              aria-controls="shipping"
              aria-selected="false"
            >
              Shipping & Free Returns
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="designer-tab"
              data-toggle="tab"
              href="#designer"
              role="tab"
              aria-controls="designer"
              aria-selected="false"
            >
              About This Designer
            </a>
          </li>
        </ul>
        <div className="tab-content hide_nav" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="description"
            role="tabpanel"
            aria-labelledby="description-tab"
          >
            <div className="container my-4">
              <div className="row">
                <div className="col-lg-8">
                  <div
                    className="product_description pars-str"
                    dangerouslySetInnerHTML={{
                      __html: this.props.prodInfo.description
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="size"
            role="tabpanel"
            aria-labelledby="size-tab"
          >
            <div className="container my-4">
              <div className="row">
                <div className="col-lg-8">
                  <div
                    className="product_description pars-str"
                    dangerouslySetInnerHTML={{
                      __html: this.props.prodInfo.sizeAndFit
                    }}
                  />
                  <a className="btn btn-secondary btn-size-guide" data-toggle="modal" data-target="#sizeModal" href="/">
                    Size Guide
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="composition"
            role="tabpanel"
            aria-labelledby="composition-tab"
          >
            <div className="container my-4">
              <div className="row">
                <div className="col-lg-8">
                  <div
                    className="product_description pars-str"
                    dangerouslySetInnerHTML={{
                      __html: this.props.prodInfo.compositionAndCare
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="shipping"
            role="tabpanel"
            aria-labelledby="shipping-tab"
          >
            <div className="container my-4">
              <div className="row">
                <div className="col-lg-8">
                  <div
                    className="product_description pars-str"
                    dangerouslySetInnerHTML={{
                      __html: this.props.prodInfo.shippingAndReturns
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="designer"
            role="tabpanel"
            aria-labelledby="designer-tab"
          >
            <div className="container my-4">
              <div className="row">
                <div className="col-lg-8">
                  <div
                    className="product_description pars-str"
                    dangerouslySetInnerHTML={{
                      __html: this.props.prodInfo.aboutDesigner
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Dropdowns */}

        <div className="accordion hide_collapse" id="accordionExample">
          <div>
            <div id="headingOne">
              <p
                className="title_collapse"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Description
                <span className="collapse_icon">
                  <i className="fas fa-chevron-down" />
                </span>
              </p>
            </div>
            <div
              id="collapseOne"
              className="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-8">
                    <div
                      className="product_description pars-str"
                      dangerouslySetInnerHTML={{
                        __html: this.props.prodInfo.description
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div id="headingTwo">
              <p
                className="title_collapse"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Size & Fit
                <span className="collapse_icon">
                  <i className="fas fa-chevron-down" />
                </span>
              </p>
            </div>
            <div
              id="collapseTwo"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-8">
                    <div
                      className="product_description pars-str"
                      dangerouslySetInnerHTML={{
                        __html: this.props.prodInfo.sizeAndFit
                      }}
                    />
                    <Link
                      to={"/size-guide"}
                      className="btn btn-secondary btn-size-guide"
                    >
                      Size Guide
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div id="headingThree">
              <p
                className="title_collapse"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Composition & Care
                <span className="collapse_icon">
                  <i className="fas fa-chevron-down" />
                </span>
              </p>
            </div>
            <div
              id="collapseThree"
              className="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-8">
                    <div
                      className="product_description pars-str"
                      dangerouslySetInnerHTML={{
                        __html: this.props.prodInfo.compositionAndCare
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div id="headingFourth">
              <p
                className="title_collapse"
                data-toggle="collapse"
                data-target="#collapseFourth"
                aria-expanded="false"
                aria-controls="collapseFourth"
              >
                Shipping & Free Returns
                <span className="collapse_icon">
                  <i className="fas fa-chevron-down" />
                </span>
              </p>
            </div>
            <div
              id="collapseFourth"
              className="collapse"
              aria-labelledby="headingFourth"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-8">
                    <div
                      className="product_description pars-str"
                      dangerouslySetInnerHTML={{
                        __html: this.props.prodInfo.shippingAndReturns
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div id="headingFive">
              <p
                className="title_collapse"
                data-toggle="collapse"
                data-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                About This Designer
                <span className="collapse_icon">
                  <i className="fas fa-chevron-down" />
                </span>
              </p>
            </div>
            <div
              id="collapseFive"
              className="collapse"
              aria-labelledby="headingFive"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-8">
                    <div
                      className="product_description pars-str"
                      dangerouslySetInnerHTML={{
                        __html: this.props.prodInfo.aboutDesigner
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SliderNav;
