//Dependencies
import React, { Component } from "react";
import { dbFirestore, auth } from "../../@firebase";
import { Redirect, Link } from "react-router-dom";
import "./confirm-lot.css";
import CountDownSan from "../../@components/countdown";
import { metasTags, formatNumber } from "../../#constants";
class ConfirmLotPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      visible: false,
      active: false,
      messageButton: "Bid",
      AgreeTerms: false,
      data: { title: "" },
      redirectCards: false,
      logged: false,
      accepted: false,
      redirectLog: false,
      redirectAuctions: false,
      lotData: {},
    };
    this.makeBid = this.makeBid.bind(this);
    this.handleRadio = this.handleRadio.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    metasTags.setMetaTags();
    auth.onAuthStateChanged((user) => {
      var logged;
      if (user) {
        logged = true;
      } else {
        logged = false;
        this.unsubscribe();
      }
      if (logged) {
        this.init(user, logged);
      } else {
        this.setState({ redirectLog: true });
      }
    });
  }

  async init(user, logged) {
    if (
      this.props.location.state !== null &&
      this.props.location.state !== undefined
    ) {
      const propsInfo = this.props.location.state.data;
      this._isMounted &&
        this.setState({
          logged: logged,
          visible: true,
          user: user,
          bidSelected: propsInfo.bidSelected,
          redirectLog: false,
        });

      try {
        let snapshot = await dbFirestore
          .collection("lots")
          .doc(propsInfo.id)
          .get();
        if (!snapshot.exists) {
          throw Error("Document dont exist");
        }
        if (!snapshot.data().publish) {
          throw Error("Lot dont publish");
        }

        this.setState({ lotData: snapshot.data() });

        let seconds = Math.round(new Date().getTime() / 1000);
        let end = propsInfo.auction.end.seconds;
        this._isMounted && this.setState({ auction: propsInfo.auction });

        if (end < seconds) {
          if (logged) {
            //Si el usuario es ganador
            if (user.uid === snapshot.data().userWin) {
              this._isMounted &&
                this.setState({ messageButton: "THANK YOU FOR YOUR BID" });
            } else {
              this._isMounted && this.setState({ messageButton: "Sold" });
            }
          } else {
            this._isMounted && this.setState({ messageButton: "Sold" });
          }
          //Se setea la que la subasta no está activa
          this._isMounted && this.setState({ active: false });
        } else {
          if (logged) {
            if (user.uid === snapshot.data().userWin) {
              this._isMounted &&
                this.setState({
                  messageButton: "THANK YOU FOR YOUR BID",
                  active: false,
                });
            } else {
              this._isMounted &&
                this.setState({ active: true, messageButton: "Bid" });
            }
          } else {
            this._isMounted &&
              this.setState({ active: true, messageButton: "Bid" });
          }
        }
        this._isMounted && this.setState({ data: snapshot.data() });
      } catch (error) {
        console.log(error);
      }
    } else {
      // redirect to home
      this._isMounted && this.setState({ redirectLog: true });
    }
  }

  componentWillReceiveProps(props) {
    this.componentDidMount();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  terminate() {
    if (this.state.logged) {
      //Si el usuario es ganador
      if (this.state.user.uid === this.state.data.userWin) {
        this._isMounted &&
          this.setState({ messageButton: "THANK YOU FOR YOUR BID" });
      } else {
        this._isMounted && this.setState({ messageButton: "Sold" });
      }
    } else {
      this._isMounted && this.setState({ messageButton: "Sold" });
    }
    //Se setea la que la subasta no está activa
    this._isMounted && this.setState({ active: false });
  }

  makeBid() {
    if (this.state.logged) {
      //Show cards
      this._isMounted && this.setState({ redirectCards: true });
    } else {
      //Go to home
      this._isMounted && this.setState({ redirectLog: true });
    }
  }

  createHeader() {
    if (this.state.data !== null && this.state.data !== undefined) {
      if (this.state.auction !== null && this.state.auction !== undefined) {
        if (
          this.state.auction.end !== null &&
          this.state.auction.end !== undefined
        ) {
          let header = [];
          header.push(
            <div className="row align-center" key="row-header">
              <div className="col-12">
                {/*<Countdown date={new Date(this.state.auction.end.seconds * 1000).getTime()}renderer={this.renderer}/>*/}
                <CountDownSan
                  date={new Date(
                    this.state.auction.end.seconds * 1000
                  ).toString()}
                  className="CountDown-center"
                  onEnd={() => {
                    this.terminate();
                  }}
                />
              </div>
            </div>
          );
          return header;
        }
      }
    }
  }

  handleRadio(e) {
    if (e.target.value === "on") {
      this._isMounted && this.setState({ accepted: true });
    }
  }

  render() {
    return (
      <div className="ConfirmLot">
        {this.state.redirectLog && <Redirect push to={"/"} />}
        {this.state.redirectAuctions && <Redirect push to={"/auctions"} />}
        <div style={{ padding: "15px 0" }}>
          <div className="container-fluid no-pd-mobile">
            <div className="row align-items-center ">
              <div className="col-12 text-center">
                <h3 className="title-confirm">Confirm your bid</h3>
                {this.createHeader()}
                <div className="img_container  col-12 col-sm-5 col-md-8 col-lg-4 mb-3 p-0">
                  <img
                    className="confirm_lot_img"
                    src={this.state.lotData.principalImage}
                    alt={this.state.lotData.title}
                  />
                </div>
                <p className="item-text-confirm">
                  Item choose:{" "}
                  <span className="item-name">{this.state.data.title}</span>
                </p>

                <div className="img_container col-12 col-sm-5 col-md-10 col-lg-5">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.data.description,
                    }}
                  />
                </div>

                <hr className="separator-confirm" />
                <p className="text-center item-text-confirm">
                  MAX BID <br />{" "}
                  <span className="item-price">
                    ${formatNumber(this.state.bidSelected)}
                  </span>
                </p>
                <hr className="separator-confirm" />
                <p className="item-text-confirm">
                  <input
                    type="radio"
                    onClick={(e) => this.handleRadio(e)}
                    name="radAnswer"
                  />
                  You agree to{" "}
                  <Link to="conditions">
                    <span className="underline">Conditions of Sale </span>
                  </Link>
                </p>
                {this.state.accepted && (
                  <button
                    disabled={!this.state.active}
                    className="btn btn-secondary btn-confirm"
                    onClick={() => {
                      this.makeBid();
                    }}
                  >
                    {this.state.messageButton}
                  </button>
                )}
                {!this.state.accepted && (
                  <button
                    disabled
                    className="btn btn-secondary btn-confirm"
                    onClick={() => {
                      this.makeBid();
                    }}
                  >
                    Bid
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.redirectCards && (
          <Redirect
            push
            to={{
              pathname: "/cards",
              state: {
                data: {
                  auction: true,
                  auctionData: this.props.location.state.data.auction,
                  lot: this.state.data.id,
                  bidSelected: this.state.bidSelected,
                },
              },
            }}
          />
        )}
      </div>
    );
  }
}

export default ConfirmLotPage;
