import React, { Component } from "react";
import "./share-modal.css";
import $ from "jquery";

class ShareModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltip: "Copy to clipboard",
      shareLink: ""
    };
    this.copyClipboard = this.copyClipboard.bind(this);
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    $("#copy").tooltip();
    this._isMounted && this.setState({ shareLink: this.props.shareLink });
  }
  copyClipboard() {
    var copyText = document.getElementById("shareLink");
    copyText.select();
    document.execCommand("copy");
    this._isMounted &&
      this.setState({ tooltip: "Copied" }, () => {
        $("#copy").tooltip("hide");
        $("#copy").tooltip("show");
      });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    return (
      <div
        className="modal fade"
        id="shareModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="shareModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered" role="document">
          <div className="modal-content share_modal">
            <div className="text-center p-3">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="share_modal_x" aria-hidden="true">
                  {" "}
                  &times;
                </span>
              </button>
              <h5 id="shareModalLabel" className="closit_text">
                Share Link
              </h5>
            </div>
            <div className="modal-body text-center share-modal-body">
              <div className="container">
                <div className="row align-items-center justify-content-center mb-3">
                  <div className="col-2">
                    <a
                      className="no_hover"
                      href={
                        "mailto:?subject=GaryNader&body=" + this.state.shareLink
                      }
                    >
                      <i className="fas fa-envelope" />
                    </a>
                  </div>
                  <div className="col-10">
                    <p className="text-left mb-0">
                      <a
                        className="share_modal_item"
                        href={
                          "mailto:?subject=GaryNader&body=" +
                          this.state.shareLink
                        }
                      >
                        Email
                      </a>
                    </p>
                  </div>
                </div>

                <div className="row align-items-center justify-content-center mb-3">
                  <div className="col-2">
                    <a
                      href="/#"
                      id="copy"
                      data-toggle="tooltip"
                      data-placement="left"
                      data-html="true"
                      data-original-title={this.state.tooltip}
                      onClick={() => {
                        this.copyClipboard();
                      }}
                    >
                      <i className="fas fa-copy" />
                    </a>
                  </div>
                  <div className="col-10">
                    <input
                      className="form-control"
                      type="text"
                      value={this.state.shareLink}
                      id="shareLink"
                      readOnly
                    />
                  </div>
                </div>

                <div className="row align-items-center justify-content-center mb-3">
                  <div className="col-2">
                    <a
                      className="no_hover"
                      data-action="share/whatsapp/share"
                      href={"https://wa.me/?text=" + this.state.shareLink}
                      _blank
                    >
                      <i className="fab fa-whatsapp" />
                    </a>
                  </div>
                  <div className="col-10">
                    <p className="text-left mb-0">
                      <a
                        className="share_modal_item "
                        data-action="share/whatsapp/share"
                        _blank
                        href={"https://wa.me/?text=" + this.state.shareLink}
                      >
                        Share Link by WhatsApp
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ShareModal;
