import React, { Component } from "react";
import { dbFirestore } from "../../@firebase";
import "./conditions.css";
import { metasTags } from "../../#constants";

class PrivateConditionsOfSale extends Component {
  constructor(props) {
    super(props);

    this.state = {
      conditions: "<p></p>",
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    metasTags.setMetaTags();
    dbFirestore
      .collection("private_conditions")
      .get()
      .then((doc) => {
        if (!doc.empty) {
          doc.forEach((value) => {
            this._isMounted &&
              this.setState({
                conditions: value.data().conditions,
              });
          });
        }
      })
      .catch((err) => console.error(err));
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    return (
      <div className="Conditions">
        <div className="container">
          <div className="setting-title_container my-4">
            <p className="mb-0">Conditions of Sale</p>
          </div>
          <div className="row">
            <div className="col-12 mb-2 ">
              <div
                className="pars-str"
                dangerouslySetInnerHTML={{ __html: this.state.conditions }}
              />
            </div>
            <div className="col-12">
              <button
                className="btn btn-secondary btn-back"
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                GO BACK
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivateConditionsOfSale;
